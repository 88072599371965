import React from 'react';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import Button from 'react-bootstrap/lib/Button';

const ShortlistButton = ({
  mobile,
  toggleShortlist,
  shortlistState,
  style,
}) => (
  <Button
    onClick={toggleShortlist}
    style={{
      ...style,
    }}
    className={`${mobile ? 'visible-xs' : 'hidden-xs'} btn btn-default`}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SvgIcon
        icon="heart"
        size="18"
        color={shortlistState ? '#D9534F' : '#D3D3D3'}
        style={{ marginRight: '8px' }}
      />
      <span
        style={{
          color: '#5F5F5F',
          fontSize: '18px',
        }}
      >
        Favourite
      </span>
    </div>
  </Button>
);

export default ShortlistButton;
