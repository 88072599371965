import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Textarea from 'react-textarea-autosize';
import { isValid, format, addYears } from 'date-fns';
import { TODAY } from 'constants/constants';
import DatePicker from 'components/Shared/DatePicker';
import styles from '../Education.module.scss';
import { Button } from 'react-bootstrap';

const EducationForm = ({
  index,
  updateEducation,
  currentEducation,
  isLast,
  addNewEducation,
}) => {
  const [newEducation, setNewEducation] = useState(currentEducation);
  const [qualification, setQualification] = useState(
    currentEducation?.qualification || ''
  );
  const [organization, setOrganization] = useState(
    currentEducation?.organization || ''
  );
  const [startDate, setStartDate] = useState(
    currentEducation?.started_at
      ? new Date(currentEducation.started_at)
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    currentEducation?.ended_at
      ? new Date(currentEducation.ended_at)
      : new Date()
  );
  const [content, setContent] = useState(currentEducation?.content || '');
  const [noName, setNoName] = useState(false);
  const [noEnd, setNoEnd] = useState(false);
  const [noSchool, setNoSchool] = useState(false);

  useEffect(() => {
    if (checkChanges()) {
      // Error handling
      setNoName(!qualification);
      setNoSchool(!organization);
      setNoEnd(!endDate);

      if (!qualification || !organization || !endDate) {
        return;
      }

      const updates = {
        ...newEducation,
        qualification: qualification,
        organization: organization,
        content: content,
        started_at: formatDate(startDate),
        ended_at: formatDate(endDate),
      };

      // Save user input
      setNewEducation(updates);

      if (updates !== currentEducation) {
        updateEducation(updates, index);
      }
    }
  }, [qualification, organization, startDate, endDate, content]);

  const checkChanges = () => {
    if (
      qualification !== newEducation.qualification ||
      organization !== newEducation.organization ||
      formatDate(startDate) !== newEducation.started_at ||
      formatDate(endDate) !== newEducation.ended_at ||
      content !== newEducation.content
    ) {
      return true;
    }
    return false;
  };

  const formatDate = (date) => format(date, 'yyyy-MM-dd HH:mm:ss');

  return (
    <div style={isLast ? { display: 'flex', flexDirection: 'column' } : null}>
      <div className="mb lato-bold mt">Qualification name:</div>
      <input
        className="form-control"
        onChange={(event) => setQualification(event.target.value)}
        name="qualification"
        placeholder="Eg: Bachelor of Science"
        type="text"
        value={qualification}
      />
      {noName && (
        <div className={`mb ${styles.warning}`}>
          You must give a qualification name.
        </div>
      )}
      <div className="mb lato-bold mt">School name:</div>
      <input
        className="form-control"
        onChange={(event) => setOrganization(event.target.value)}
        name="organization"
        placeholder="Eg: Stanford University"
        type="text"
        value={organization}
      />

      {noSchool && (
        <div className={`mb ${styles.warning}`}>
          You must give a school name.
        </div>
      )}
      <div className="mb lato-bold mt">Start date (optional):</div>
      <DatePicker
        className="form-control"
        isClearable={false}
        dateFormat="do MMMM yyyy"
        showYearDropdown
        maxDate={addYears(TODAY, 1)}
        onChange={(date) => {
          if (isValid(date)) {
            setStartDate(date);
          }
        }}
        name="started_at"
        placeholderText="Select availability"
        selected={startDate}
      />
      <div className="mb lato-bold mt">End date:</div>
      <DatePicker
        className="form-control"
        isClearable={false}
        dateFormat="do MMMM yyyy"
        showYearDropdown
        maxDate={addYears(TODAY, 10)}
        onChange={(date) => {
          if (isValid(date)) {
            setEndDate(date);
          }
        }}
        name="ended_at"
        placeholderText="Select availability"
        selected={endDate}
      />
      {noEnd && (
        <div className={`mb ${styles.warning}`}>
          You must give a qualification end date.
        </div>
      )}
      <div className="mb lato-bold mt">Description (optional):</div>
      <Textarea
        className="form-control mb"
        style={{ height: '100%', resize: 'none' }}
        placeholder="Add any extra details you would like to share here."
        onChange={(event) => setContent(event.target.value)}
        name="content"
        value={content}
        minRows={1}
        maxRows={30}
        maxLength="3000"
      />
      {!isLast && <hr />}
      {isLast && (
        <Button
          bsStyle={'primary'}
          style={{
            alignSelf: 'center',
            width: '40%',
            maxWidth: '100%',
            marginTop: '10',
          }}
          disabled={noName || noSchool || noEnd}
          onClick={() => addNewEducation({})}
        >
          <strong>+ Add Education</strong>
        </Button>
      )}
    </div>
  );
};

EducationForm.propTypes = {
  index: PropTypes.number,
  currentEducation: PropTypes.object,
  updateEducation: PropTypes.func,
  isLast: PropTypes.bool,
  addNewEducation: PropTypes.func,
};

export default EducationForm;
