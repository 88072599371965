import React, { memo } from 'react';
import { toPlural, toSeoUrl } from 'helpers/HelperFunctions';
import styles from './Skill.module.scss';
import LinkOrAnchor from 'components/Shared/LinkOrAnchor';

const skillRoleObject = {
  Anime: 'Animators',
  Claymation: 'Animators',
  Icon: 'Graphic Designers',
  Rotoscope: 'Animators',
  'Whiteboard Video': 'Animators',
  'Adobe Animate': '2D Animators',
  Pencil2D: '2D Animators',
  'Reallusion Cartoon': '2D Animators',
  MikuMikuDance: '3D Animators',
  Mixamo: '3D Animators',
  'Reallusion iClone': '3D Animators',
  'Reallusion Character Creator': '3D Animators',
  'Stop Action': 'Photographers',
  'Stop Motion': 'Photographers',
  CSS: 'Web Developers',
  Android: 'App Developers',
  HTML5: 'Web Developers',
  Drupal: 'CMS Developers',
  'Ableton Live': 'Music Producers',
  Bootstrap: 'Web Developers',
  CakePHP: 'Web Developers',
  CodeIgniter: 'Web Developers',
  'C++': 'Developers',
  'FL Studio': 'Music Producers',
  Django: 'Web Developers',
  'Email Marketing': 'Digital Marketers',
  Erlang: 'Developers',
  Blender: 'Animators',
  Azure: 'Cloud Developers',
  'Adobe Photoshop': 'Graphic Designers',
  AngularJS: 'Web Developers',
  '.NET': 'Developers',
  'Adobe Audition': 'Sound Editors',
  Camtasia: 'Video Editors',
  'ASP.NET': 'Developers',
  'Google Ads': 'Digital Marketers',
  'Facebook Ads': 'Digital Marketers',
  'Final Cut Pro X': 'Video Editors',
  'Adobe After Effects': 'Motion Graphic Designers',
  'Adobe Illustrator': 'Designers',
  'Adobe Lightroom': 'Photographers',
  GIMP: 'Graphic Designers',
  'Adobe Premiere Pro': 'Video Editors',
  HubSpot: 'Digital Marketers',
  Finale: 'Music Composers',
  'Adobe XD': 'UI Designers',
  CorelDraw: 'Graphic Designers',
  'Adobe InDesign': 'Graphic Designers',
  'Autodesk Maya': '3D Animators',
  'Adobe Dreamweaver': 'Web Designers',
  'Adobe Spark': 'Digital Marketers',
  Grails: 'Developers',
  Hadoop: 'Developers',
  'Adobe Character Animator': '2D Animators',
  Akeneo: 'Developers',
  'Amazon Web Services': 'Cloud Developers',
  Bitcoin: 'Blockchain Developers',
  C: 'Developers',
  'CelAction Animatic': 'Animators',
  CelAction2D: 'Animators',
  'DaVinci Resolve Studio': 'Video Editors',
  'Ember.js': 'Web Developers',
  Go: 'Developers',
  WordPress: 'Web Developers',
  'Pro Tools': 'Music Producers',
  JavaScript: 'Web Developers',
  Java: 'Developers',
  Python: 'Developers',
  iOS: 'App Developers',
  PHP: 'Web Developers',
  jQuery: 'Web Developers',
  Symfony: 'Web Developers',
  POS: 'Developers',
  MongoDB: 'Database Developers',
  Invision: 'Graphic Designers',
  Magento: 'Web Developers',
  Laravel: 'Web Developers',
  Shopify: 'Developers',
  WiX: 'Web Developers',
  MySQL: 'Database Developers',
  Unity: 'Game Developers',
  SaaS: 'Developers',
  Squarespace: 'Web Developers',
  'React Native': 'Developers',
  LMS: 'Developers',
  Sibelius: 'Music Composers',
  PostgreSQL: 'Database Developers',
  Ruby: 'Web Developers',
  'Ruby on Rails': 'Web Developers',
  Salesforce: 'Developers',
  Kotlin: 'Developers',
  Zend: 'Web Developers',
  ionic: 'Developers',
  Mailchimp: 'Digital Marketers',
  'Microsoft Excel': 'Developers',
  Spring: 'Developers',
  'Microsoft Powerpoint': 'Graphic Designers',
  PayPal: 'Developers',
  'Unity 3D': 'Developers',
  XML: 'Developers',
  'Vegas Pro': 'Video Editors',
  'Unreal Engine 4': 'Game Developers',
  'Logic Pro': 'Music Producers',
  VMware: 'DevOps Developers',
  Jira: 'Developers',
  iMovie: 'Video Editors',
  Jimdo: 'Web Developers',
  'MAXON Cinema 4D': 'Animators',
  'Node.js': 'Web Developers',
  phpBB: 'Web Developers',
  'Propellerhead Reason': 'Music Producers',
  Qt: 'Developers',
  'React JS': 'Web Developers',
  'Sails.js': 'Web Developers',
  Scala: 'Developers',
  'SGO Mistika': 'Video Editors',
  Smarty: 'Developers',
  Twilio: 'Developers',
  'Twitter Ads': 'Digital Marketers',
  'Unreal Engine 3': 'Game Developers',
  web2py: 'Developers',
  WebKit: 'Developers',
  Weebly: 'Developers',
  Xamarin: 'Developers',
  Webflow: 'Web Developers',
  AWS: 'Cloud Developers',
  Airtable: 'Developers',
  Facebook: 'Social Media Freelancers',
  Twitter: 'Social Media Freelancers',
  Instagram: 'Social Media Freelancers',
  Pinterest: 'Social Media Freelancers',
  Snapchat: 'Social Media Freelancers',
  Tiktok: 'Social Media Freelancers',
  LinkedIn: 'Social Media Freelancers',
  Quora: 'Social Media Freelancers',
  Reddit: 'Social Media Freelancers',
  Figma: 'Designers',
  Elementor: 'Web Developers',
  'Corel Draw': 'Graphic Designers',
  Zapier: 'Developers',
  Outfunnel: 'Digital Marketers',
  Stripe: 'Developers',
  Monday: 'Developers',
  Retool: 'Developers',
  'Slack Workflow': 'Developers',
  'UI Path': 'Developers',
  'Twitter API': 'Developers',
  TensorFlow: 'AI Engineers',
  PyTorch: 'AI Engineers',
  Keras: 'AI Engineers',
  'Facebook API': 'Developers',
  'Instagram API': 'Developers',
  'C#': 'Developers',
  Dart: 'Developers',
  Express: 'Web Developers',
  GraphQL: 'Developers',
  Lua: 'Developers',
  R: 'Developers',
  Rust: 'Developers',
  TypeScript: 'Web Developers',
  'Vue.js': 'Web Developers',
  'Objective-C': 'Developers',
  Swift: 'Developers',
  Flutter: 'Developers',
  SQL: 'Database Developers',
  DynamoDB: 'Database Developers',
  Elasticsearch: 'Developers',
  MariaDB: 'Database Developers',
  'Microsoft SQL': 'Database Developers',
  Neo4j: 'Database Developers',
  Redis: 'Database Developers',
  DB2: 'Database Developers',
  SQLite: 'Database Developers',
  Cassandra: 'Database Developers',
  Oracle: 'Database Developers',
  Snowflake: 'Developers',
  'Google Cloud': 'Cloud Developers',
  Selenium: 'QA Engineers',
  'Blue Prism': 'Developers',
  Docker: 'DevOps Developers',
  Ethereum: 'Blockchain Developers',
  Kubernetes: 'DevOps Developers',
  Solidity: 'Blockchain Developers',
  Canva: 'Graphic Designers',
};

const Skill = ({ skill, userType }) => {
  if (!skill) return <div className={styles.skillEmpty}></div>;
  let imgUrl = '';
  let divStyle;
  let skillClassName = 'backgroundImage';

  try {
    imgUrl =
      require(`static/images/portfolios/skills/${skill.slug.toLowerCase()}.png`).default;
    divStyle = { backgroundImage: `url(${imgUrl})` };
  } catch (err) {
    divStyle = {};
    skillClassName = 'noLogo';
  }

  const SkillTile = (
    <div className={styles.skillContainer}>
      <div className={styles.skill}>
        <div className={styles[skillClassName]} style={divStyle}>
          {!imgUrl && skill.name.slice(0, 2)}
        </div>
      </div>
      {skill.name}
    </div>
  );

  const role = toSeoUrl(toPlural(skillRoleObject[skill.name]));
  const linkToFindPage = role && userType === 'guest';
  let url = `/browse?page=1&skill=${skill.slug}`;
  if (linkToFindPage) url = `/find/${role}/with/${skill.slug}`;

  return userType === 'buyer' || linkToFindPage ? (
    <a href={url}>{SkillTile}</a>
  ) : (
    SkillTile
  );
};

export default memo(Skill);
