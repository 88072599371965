import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import formatDistance from 'date-fns/formatDistance';

export default class LastSeen extends Component {
  static propTypes = {
    large: PropTypes.bool,
    user: PropTypes.object,
  };
  static defaultProps = {
    user: { last_active: '' },
    large: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      diff: this.getDiff(),
    };
  }

  getDiff = () => {
    const { user } = this.props;
    const now = new Date();
    const lastActive = new Date(user.last_active);
    return (now - lastActive) / 1000;
  };

  render() {
    const { user, large } = this.props;
    const { diff } = this.state;

    return (
      <OverlayTrigger
        className="hidden-xs"
        placement="right"
        overlay={
          <Popover id="last-online-popover" placement="right">
            <span className="lato-bold">Last online: </span>
            {formatDistance(new Date(user.last_active), new Date())} ago
          </Popover>
        }
      >
        <span
          className="hidden-xs"
          style={{
            marginLeft: '5px',
            height: large ? '1.5em' : '1em',
            width: large ? '1.5em' : '1em',
            backgroundColor:
              diff < 60 * 60 * 24
                ? '#89cf79'
                : diff < 60 * 60 * 24 * 7
                ? '#ff995c'
                : '#777777',
            borderRadius: '50%',
            display: 'inline-block',
          }}
        />
      </OverlayTrigger>
    );
  }
}
