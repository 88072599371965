import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './RangeSlider.module.scss';

// This is a reusable component\
// It has been designed to be flexible and reusable
export default class Slider extends Component {
  static propTypes = {
    // Pass in the initial value of the slider
    sliderValue: PropTypes.number,
    // Color to the right of the handle
    colorRight: PropTypes.string,
    // Color to the left of the handle
    colorLeft: PropTypes.string,
    // Index is used to identify this particular slider
    // By default it is 0
    index: PropTypes.number,
    // Min value for the slider
    min: PropTypes.number,
    // Max value for the slider
    max: PropTypes.number,
    // Update function
    // 1st parameter -> the value of the slider
    // 2nd parameter -> the index passed into the slider
    // The index parameter is optional for when
    // you are dynamically generating multiple sliders
    update: PropTypes.func,
  };

  static defaultProps = {
    sliderValue: 0,
    index: 0,
    min: 0,
    max: 100,
    colorLeft: '#2cae76',
    colorRight: '#D8DEE3',
  };

  constructor(props) {
    super(props);

    this.state = {
      sliderValue: props.sliderValue,
    };
  }

  componentDidMount() {
    const { index } = this.props;
    const { sliderValue } = this.state;
    // Slider must be updated as soon as the component has mounted
    // As the handle position must match the passed in sliderValue
    this.updateSlider(sliderValue, index);
  }

  handleInputChange(event) {
    const { index, update } = this.props;
    let { value } = event.target;
    value = parseInt(value, 0);

    // Update the sliderValue
    this.setState({ sliderValue: value });
    // Send update to the parent component
    update(value, index);
    // Update the slider to match the new value
    this.updateSlider(value, index);
  }

  updateSlider(value, index) {
    const { colorLeft, colorRight, min, max } = this.props;
    // Get the slider element from the page
    const slider = document.getElementById(`slider${index}`);

    // Calculate how far the handle is across the slider as percentage of 100
    const percent = ((value - min) / (max - min)) * 100;

    if (slider) {
      // Update the left and right colors to match the position of the handle as it moves across the page
      slider.style.background = `linear-gradient(to right, ${colorLeft} 0%, ${colorLeft} ${percent}%, ${colorRight} ${percent}%, ${colorRight} 100%)`;
    }
  }

  render() {
    const { min, max, index } = this.props;
    const { sliderValue } = this.state;

    return (
      <div className={styles.sliderContainer}>
        <input
          type={'range'}
          min={min}
          max={max}
          value={sliderValue}
          className={styles.slider}
          id={`slider${index}`}
          onChange={(event) => this.handleInputChange(event)}
        />
      </div>
    );
  }
}
