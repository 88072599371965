import PropTypes from 'prop-types';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';

import EducationForm from './EducationForm/EducationForm';
import EducationDetails from './EducationDetails/EducationDetails';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'react-bootstrap';
import SvgIcon from 'components/SvgIcon/SvgIcon';

import { useDispatch } from 'react-redux';
import { update as updateUser } from 'redux/modules/auth';
import useAuth from 'hooks/useAuth';

import styles from './Education.module.scss';
import hocStyles from '../../hoc/hoc.module.scss';
import Loader from 'components/Loader/Loader';

const { headerContainer, header, editBtn, editWrp, loader } = hocStyles;

const Education = ({ user, isOwn, userData, isEmpty, placeholder }) => {
  const [education, setEducation] = useState(userData);
  const [displayedEducation, setDisplayedEducation] = useState(
    userData.filter((item) => item.organization)
  );
  const dispatch = useDispatch();
  const { lastUpdated, updating, updated } = useAuth();
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setEducation(userData);
    setDisplayedEducation(userData.filter((item) => item.organization));
  }, [userData]);

  const addNewEducation = useCallback(
    (newEducation) => {
      const updatedEducation = [...education, newEducation];
      setEducation(updatedEducation);

      const updatedDisplayedEducation = [...displayedEducation, newEducation];
      setDisplayedEducation(updatedDisplayedEducation);
    },
    [education, displayedEducation]
  );

  const deleteEducation = useCallback(
    (index) => {
      const educationToDelete = displayedEducation[index];
      const updatedDisplayedEducation = displayedEducation.filter(
        (_, idx) => index !== idx
      );
      setDisplayedEducation(updatedDisplayedEducation);

      const originalIndex = education.findIndex(
        (ed) => ed === educationToDelete
      );
      const updatedEducation = education.filter(
        (_, idx) => originalIndex !== idx
      );
      setEducation(updatedEducation);
      handleEdit('education', updatedEducation);
    },
    [education, displayedEducation]
  );

  const updateEducation = useCallback(
    (updatedEducation, index) => {
      const editedEducation = _.cloneDeep(education);
      const displayedItem = displayedEducation[index];
      const originalIndex = education.findIndex((ed) => ed === displayedItem);
      editedEducation[originalIndex] = updatedEducation;
      setEducation(editedEducation);

      const updatedDisplayedEducation = _.cloneDeep(displayedEducation);
      updatedDisplayedEducation[index] = updatedEducation;
      setDisplayedEducation(updatedDisplayedEducation);
    },
    [education, displayedEducation]
  );

  const handleEdit = useCallback(
    (editMode, educationUpdates = null) => {
      const updates = educationUpdates
        ? { education: educationUpdates }
        : { education: education };

      if (Object.keys(education).length > 0) {
        dispatch(
          updateUser(
            user,
            { ...updates, portfolioUpdate: true },
            false,
            [
              'skills:accepted(null)',
              'sectors',
              'tools',
              'education',
              'language',
              'diversity',
            ],
            '',
            editMode
          )
        ).then(() => {
          setEducation(education);
          setDisplayedEducation(
            educationUpdates
              ? educationUpdates.filter((education) => education.organization)
              : displayedEducation.filter((education) => education.organization)
          );
          setEditing(false);
        });
      } else {
        setEditing(false);
      }
    },
    [dispatch, education, user]
  );

  const renderEducationDetails = useCallback(() => {
    return displayedEducation.map((educationItem, index) => {
      const lastEntry = displayedEducation.length - index === 1;
      const key = educationItem.id
        ? `edDetails${educationItem.id}`
        : `edDetails${educationItem.ended_at}`;
      if (!educationItem.organization) return <noscript key={key} />;
      return (
        <EducationDetails
          key={key}
          lastEntry={lastEntry}
          index={index}
          education={educationItem}
          isOwn={isOwn}
          user={user}
          deleteEducation={() => deleteEducation(index)}
        />
      );
    });
  }, [displayedEducation]);

  const renderEducationForms = () => {
    const noEducation = displayedEducation.length === 0;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {displayedEducation.map((educationItem, index) => {
          const isLast = index === displayedEducation.length - 1;
          return (
            <EducationForm
              key={index}
              index={index}
              updateEducation={updateEducation}
              currentEducation={educationItem}
              isLast={isLast}
              deleteEducation={() => deleteEducation(index)}
              addNewEducation={addNewEducation}
            />
          );
        })}
        {noEducation && (
          <Button
            bsStyle={'primary'}
            style={{
              alignSelf: 'center',
              width: '40%',
              maxWidth: '100%',
              marginTop: '10',
            }}
            onClick={() => addNewEducation({})}
          >
            <strong>+ Add Education</strong>
          </Button>
        )}
      </div>
    );
  };

  const instanceIsUpdating = useMemo(
    () => lastUpdated === 'education',
    [lastUpdated]
  );

  const successTick = !updating &&
    instanceIsUpdating &&
    updated &&
    !editing && <SvgIcon size="25" icon="tick_circle" color="#0fe2af" />;

  return (
    <div className={styles.container}>
      <div className={headerContainer}>
        <h3 className={header} style={{ marginTop: '5px' }}>
          Education
        </h3>
        {user && isOwn && (
          <div className={editWrp} style={{ left: '2em', paddingRight: 20 }}>
            {successTick}
            {instanceIsUpdating && updating ? (
              <Loader loading className={loader} />
            ) : (
              <button
                id="edit-bio"
                className={editBtn}
                onClick={() => setEditing(true)}
                type="button"
              >
                <SvgIcon size="21" icon="pencil" color="#666666" />
              </button>
            )}
          </div>
        )}
      </div>
      {isEmpty && !editing && placeholder}
      {renderEducationDetails()}
      <br />

      <Modal show={editing} onHide={() => handleEdit('education')}>
        <ModalHeader closeButton>
          <h4 className="lato-bold">Editing Education</h4>
        </ModalHeader>
        <ModalBody bsClass={styles.editModal}>
          {renderEducationForms()}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={updating}
            onClick={() => {
              setEducation(userData);
              setEditing(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={updating}
            onClick={() => handleEdit('education')}
            bsStyle="primary"
            className="ml--"
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

Education.propTypes = {
  user: PropTypes.object,
  isOwn: PropTypes.bool,
  userData: PropTypes.array,
  isEmpty: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default Education;
