import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Icon from 'components/Icon/Icon';
import styles from '../Education.module.scss';

const EducationDetails = ({
  lastEntry,
  index,
  education,
  deleteEducation,
  isOwn,
  user,
}) => {
  const handleDeleteEducation = () => {
    deleteEducation(index);
  };

  const end = moment(education.ended_at).format('LL');

  let dates = <i>{end}</i>;
  let topLine = (
    <div>
      <i style={{ color: '#aaa' }}>{education.qualification}</i>
    </div>
  );
  let className = 'mb';

  if (education.started_at) {
    const start = moment(education.started_at).format('LL');
    dates = (
      <i>
        {start} - {end}
      </i>
    );
  }

  if (education.organization) {
    topLine = (
      <div>
        <i style={{ color: '#aaa' }}>{education.qualification}</i> at{' '}
        {education.organization}
      </div>
    );
  }

  if (lastEntry) className = '';

  return (
    <div className={`${className} ${styles.oneEducation}`}>
      <div>
        {topLine}
        {dates}
        <div>{education.content}</div>
      </div>
      {user && isOwn && (
        <div>
          <Icon
            icon="&#xe611;"
            style={{ marginLeft: '5px' }}
            onClick={handleDeleteEducation}
          />
        </div>
      )}
    </div>
  );
};

EducationDetails.propTypes = {
  lastEntry: PropTypes.bool,
  index: PropTypes.number,
  education: PropTypes.object,
  deleteEducation: PropTypes.func,
  isOwn: PropTypes.bool,
  user: PropTypes.object,
};

export default EducationDetails;
