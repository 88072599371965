import PropTypes from 'prop-types';
import React from 'react';
import Shiitake from 'components/Shiitake/Shiitake';

const BioSnippet = ({ user, ...rest }) =>
  user && user.bio && <Shiitake {...rest}>{user.bio}</Shiitake>;

BioSnippet.propTypes = {
  user: PropTypes.object,
};

export default BioSnippet;
