import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './PortfolioTour.module.scss';

// Components for steps
const Step1 = ({}) => {
  return (
    <div className={styles.stepContainer}>
      <div>
        We’ve made some big changes to portfolios so you can showcase your work
        even more effectively.
      </div>
      <br />
      <div>Click Next to find out more.</div>
    </div>
  );
};

const Step2 = ({}) => {
  const updatingSkills = require('./assets/updating-skills.gif').default;
  return (
    <div className={styles.stepContainer}>
      <div>
        Portfolios may have changed but editing is still as simple as ever.
      </div>
      <br />
      <div>
        Click on the ‘Edit Header’ button to edit your name, profile pic, cover
        image, location and creative roles.
      </div>
      <br />
      <div>
        <img
          alt="Updating skills on your portfolio"
          src={updatingSkills}
          className={styles.stepImage}
        />
      </div>
    </div>
  );
};

const Step3 = ({}) => {
  const languageComponent = require('./assets/language-component.png').default;
  return (
    <div className={styles.stepContainer}>
      <div>Showcase your language skills in our new portfolio section.</div>
      <br />
      <div>Hit save when ready.</div>
      <br />
      <div>
        <img
          alt="Language component"
          src={languageComponent}
          className={styles.stepImage}
        />
      </div>
    </div>
  );
};

const Step4 = ({}) => {
  const diversityComponent =
    require('./assets/diversity-component.png').default;
  return (
    <div className={styles.stepContainer}>
      <div>Do you identify as being part of an underrepresented group?</div>
      <br />
      <div>
        Click on the pencil on the top right of the ‘Diversity &amp; Inclusion’
        card and select any categories that are applicable to you.
      </div>
      <br />
      <div>
        <img
          alt="Diversity component"
          src={diversityComponent}
          className={styles.stepImage}
        />
      </div>
    </div>
  );
};

// TourSteps component
const stepComponentAnimation = {
  classNames: 'animation-fadein-v1',
  transitionEnter: true,
  // transitionAppear: false,
  transitionExit: false,
  timeout: {
    enter: 500,
  },
};

const TourSteps = ({ currentStep }) => {
  let RenderedStep = null;
  switch (currentStep) {
    case 1:
      RenderedStep = Step1;
      break;
    case 2:
      RenderedStep = Step2;
      break;
    case 3:
      RenderedStep = Step3;
      break;
    case 4:
      RenderedStep = Step4;
      break;
    default:
      RenderedStep = Step1;
      break;
  }

  return (
    <div>
      <TransitionGroup>
        <CSSTransition {...stepComponentAnimation}>
          <div key={currentStep}>
            <RenderedStep />
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

TourSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

TourSteps.defaultProps = {
  currentStep: 1,
};

export default TourSteps;
