import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SectorsSelector from 'components/Shared/Selectors/SectorsSelector';
import { withEditFunctionality } from 'components/Portfolio/PortfolioAbout/components/hoc/withEditFunctionality';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

@withEditFunctionality
export default class IndustryExperience extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    editMode: PropTypes.bool,
    updateData: PropTypes.func,
    user: PropTypes.any,
  };

  updateSectors = (sectors) => {
    const newSectors = Array.isArray(sectors) ? sectors : [sectors];
    const { updateData: updateSectors } = this.props;
    const sectorsList = newSectors.map((sectors) => sectors.value);
    updateSectors(sectorsList);
  };

  renderSectors = (sectors) =>
    sectors.map((sector, key) => (
      <span key={key}>
        {sector}
        {key !== sectors.length - 1 && <span>, </span>}
      </span>
    ));

  render() {
    const { data: sectors, editMode, user } = this.props;
    return (
      (sectors.length > 0 || editMode) && (
        <div id="portfolio-sectors">
          {editMode ? (
            <SectorsSelector
              existing={user.sectors}
              onSelect={this.updateSectors}
              multi={user.type === 'seller'}
              style={{ width: '100%', marginBottom: '20px' }}
            />
          ) : (
            <TransitionGroup>
              <CSSTransition
                classNames="animate"
                timeout={{ enter: 300, exit: 500 }}
              >
                <div style={{ marginBottom: '20px' }}>
                  {this.renderSectors(sectors)}
                </div>
              </CSSTransition>
            </TransitionGroup>
          )}
        </div>
      )
    );
  }
}
