import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Button from 'react-bootstrap/lib/Button';
import { withEditFunctionality } from 'components/Portfolio/PortfolioAbout/components/hoc/withEditFunctionality';
import SelectSelectize from 'components/Shared/Selectors/SelectSelectize';
import { languages as possibleLanguages } from 'constants/languages';
import { EditableSliderBar } from 'components/Shared/Sliders/EditableSliderBar/EditableSliderBar';

const languageLevels = {
  0: '',
  1: 'Beginner',
  31: 'Intermediate',
  61: 'Advanced',
  91: 'Fluent',
};

@withEditFunctionality
export default class Language extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    editMode: PropTypes.bool,
    isOwner: PropTypes.bool,
    updateData: PropTypes.func,
  };

  state = { adding: false };

  componentDidUpdate(prevProps) {
    if (prevProps.editMode === true && this.props.editMode === false) {
      this.state.adding = false;
    }
  }

  deleteLanguage = (index) => {
    const { data: languages, updateData: updateExperience } = this.props;
    delete languages[index];
    updateExperience(languages);
  };

  addLanguage = (selected) => {
    const { data: languages, updateData: updateExperience } = this.props;

    updateExperience([
      ...languages,
      { language: selected.value, experience: 0 },
    ]);
  };

  updateExperience = (exp, index) => {
    const { data: languages, updateData: updateExperience } = this.props;
    languages[index].experience = exp;
    updateExperience(languages);
  };

  render() {
    const { data: languages, editMode, isOwner } = this.props;
    const { adding } = this.state;

    return (
      <div id="portfolio-skills">
        <div style={{ marginBottom: '20px' }}>
          {languages.map((language, index) => (
            <EditableSliderBar
              key={`LanguageBar${index}`}
              id={index}
              isLast={index === languages.length - 1}
              experience={language.experience}
              barName={language.language}
              allowDelete
              deleteValue={this.deleteLanguage}
              // Don't show experience 0 bars to clients
              showBar={language.experience || isOwner}
              textOptions={languageLevels}
              editMode={editMode}
              updateFunction={this.updateExperience}
            />
          ))}
        </div>
        {editMode && !adding && (
          <Button
            bsStyle="info"
            onClick={() => this.setState({ adding: true })}
          >
            + Add Languages
          </Button>
        )}
        {editMode && adding && (
          <SelectSelectize
            placeholder="Add a new language"
            options={possibleLanguages.map((language) => ({
              label: language,
              value: language,
            }))}
            onValuesChange={this.addLanguage}
            hideResetButton
            style={{ width: '100%', marginBottom: '10px' }}
            id={'Language-Language-addLanguageInput'}
          />
        )}
      </div>
    );
  }
}
