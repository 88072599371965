import React, { memo } from 'react';
import styles from './SkillsTiles.module.scss';
import Skill from './Skill/Skill';

const SkillsTiles = ({ skills, showMore, rowLength, userType }) => {
  const skillsRows = [];

  // Populate skillsRows as a 2D array
  for (let i = 0; i < skills.length; i += rowLength) {
    const skillsRow = [];
    // Add (n = rowLength) skills into each row
    for (let j = 0; j < rowLength; j++) skillsRow.push(skills[i + j]);
    skillsRows.push(skillsRow);
    // If showMore is false, only render one row
    if (!showMore) break;
  }

  return (
    <div>
      {/* Map through each row */}
      {skillsRows.map((skillsRow, i) => (
        <div key={`row${i}`} className={styles.row}>
          {/* Map through each skill */}
          {skillsRow.map((skill, i) => (
            <Skill key={`skill${i}`} userType={userType} skill={skill} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default memo(SkillsTiles);
