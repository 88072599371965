import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withEditFunctionality } from 'components/Portfolio/PortfolioAbout/components/hoc/withEditFunctionality';
import { EditableSliderBar } from 'components/Shared/Sliders/EditableSliderBar/EditableSliderBar';
import styles from './Experience.module.scss';

@withEditFunctionality
export default class Experience extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    editMode: PropTypes.bool,
    updateData: PropTypes.func,
    loggedInUser: PropTypes.object,
    isOwner: PropTypes.bool,
  };

  state = {
    showMore: false,
  };

  updateExperience = (exp, index) => {
    const { data: skills, updateData: updateExperience } = this.props;
    skills[index].experience = exp;
    updateExperience(skills);
  };

  render() {
    const { data: skills, editMode, loggedInUser, isOwner } = this.props;
    const { showMore } = this.state;
    const user = {
      type: loggedInUser ? loggedInUser.type : 'guest',
      isOwner: isOwner,
      hasSkills: skills.length > 0,
    };

    const showMoreButton = skills.length > 10;

    return (
      user.hasSkills && (
        <div>
          <div id="portfolio-skills">
            {editMode && (
              <div>
                Skills will be reordered by experience level once saved.
              </div>
            )}
            <div className="mt+ mb">
              <div
                id="expandable_experience"
                className={
                  !showMore && !editMode && showMoreButton ? styles.fade : ''
                }
                style={{
                  maxHeight:
                    showMore || editMode || !showMoreButton ? 'none' : '400px',
                  overflow:
                    showMore || editMode || !showMoreButton
                      ? 'visible'
                      : 'hidden',
                }}
              >
                {skills.map((skill, index) => (
                  <EditableSliderBar
                    key={`LanguageBar${index}`}
                    id={index}
                    isLast={index === skills.length - 1}
                    experience={skill.experience}
                    barName={skill.name}
                    // only show links to buyers
                    linkBarName={user.type === 'buyer'}
                    link={`/browse?page=1&skill=${skill.name}`}
                    // Don't show experience 0 bars to clients
                    showBar={skill.experience || user.isOwner}
                    user={user}
                    editMode={editMode}
                    updateFunction={this.updateExperience}
                  />
                ))}
              </div>
            </div>
            {!editMode && showMoreButton && (
              <a
                onClick={() => this.setState({ showMore: !showMore })}
                style={{
                  margin: '10px auto 0',
                  display: 'block',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                See {showMore ? 'less' : 'more'}
              </a>
            )}
          </div>
        </div>
      )
    );
  }
}
