import React from 'react';
import { toSeoUrl } from 'helpers/HelperFunctions';
import styles from './RoleTag.module.scss';
import LinkOrAnchor from 'components/Shared/LinkOrAnchor';

const RoleTag = ({ user, role, idx }) => {
  let hrefLink = `/find/${toSeoUrl(role.name)}s`;
  let titleText = `Find more ${role.name}s`;
  if (user && user.type === 'seller') {
    hrefLink = null;
    titleText = null;
  } else if (user && user.type === 'buyer') {
    hrefLink = `/browse?page=1&role=${toSeoUrl(role.name)}`;
    titleText = `browse more ${role.name}s`;
  }

  if (idx !== 0) {
    return (
      <span key={idx}>
        <LinkOrAnchor
          isLink={false}
          to={hrefLink ? hrefLink : ''}
          title={titleText}
          className={`label label-primary ${styles.roleTag}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {role.name}
        </LinkOrAnchor>
      </span>
    );
  }
  return (
    <LinkOrAnchor
      isLink={false}
      to={hrefLink ? hrefLink : ''}
      title={titleText}
      className={`label label-primary ${styles.roleTag}`}
      key={idx}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {role.name}
    </LinkOrAnchor>
  );
};

export default RoleTag;
