import PropTypes from 'prop-types';
import React from 'react';

import Button from 'react-bootstrap/lib/Button';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import styles from './PortfolioTour.module.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const previousButtonAnimation = {
  classNames: 'animation-fadein-v1',
  transitionEnter: true,
  // transitionAppear: true,
  transitionExit: true,
  timeout: {
    enter: 500,
    exit: 500,
  },
};

const Navigation = ({ currentStep, maximumStep, onPrev, onNext }) => (
  <div className={styles.navContainer}>
    {currentStep === 1 ? (
      <span className={styles.navButtonPlaceholder} />
    ) : (
      <TransitionGroup>
        <CSSTransition {...previousButtonAnimation}>
          <Button bsStyle="info" className={styles.navButton} onClick={onPrev}>
            <div className={styles.navIconContainer}>
              <SvgIcon icon="chevron-left" size="14" />
              <div className={styles.navLabel}>Previous</div>
            </div>
          </Button>
        </CSSTransition>
      </TransitionGroup>
    )}

    <div className={styles.navStepCount}>
      {currentStep} / {maximumStep}
    </div>

    <Button bsStyle="info" className={styles.navButton} onClick={onNext}>
      {currentStep === maximumStep ? (
        <div className={styles.navIconContainer}>
          <div className={styles.navLabel}>Close</div>
          <SvgIcon icon="tick" size="14" />
        </div>
      ) : (
        <div className={styles.navIconContainer}>
          <div className={styles.navLabel}>Next</div>
          <SvgIcon icon="chevron-right" size="14" />
        </div>
      )}
    </Button>
  </div>
);
Navigation.propTypes = {
  currentStep: PropTypes.number.isRequired,
  maximumStep: PropTypes.number.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  currentStep: 1,
  maximumStep: 1,
  onPrev: () => {},
  onNext: () => {},
};

export default Navigation;
