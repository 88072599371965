import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './PortfolioTour.module.scss';

const headerTitles = [
  `New Portfolio Features 🎉`,
  `Updating your portfolio`,
  `Language Skills`,
  `Diversity & Inclusion`,
];

const headerLabelAnimation = {
  classNames: 'animation-fadein-v1',
  transitionEnter: true,
  // transitionAppear: false,
  transitionExit: false,
  timeout: { enter: 500 },
};

const Header = ({ currentStep }) => (
  <div className={styles.headerContainer}>
    <TransitionGroup>
      <CSSTransition {...headerLabelAnimation}>
        <h3 key={currentStep} className={styles.headerLabel}>
          {headerTitles[currentStep - 1]}
        </h3>
      </CSSTransition>
    </TransitionGroup>
  </div>
);

Header.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

Header.defaultProps = {
  currentStep: 1,
};

export default Header;
