import PropTypes from 'prop-types';
import React from 'react';

import Header from './TourHeader';
import Navigation from './TourNavigation';
import TourSteps from './TourSteps';

import styles from './PortfolioTour.module.scss';

export default class PortfolioTour extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      maximumStep: 4,
    };
  }

  handleNext = () => {
    const { currentStep, maximumStep } = this.state;

    if (currentStep + 1 <= maximumStep) {
      this.setState({
        currentStep: currentStep + 1,
      });
    } else {
      this.handleClose();
    }
  };

  handlePrev = () => {
    const { currentStep } = this.state;

    if (currentStep - 1 >= 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    }
  };

  handleClose = () => {
    const { onExit } = this.props;
    onExit();
  };

  render() {
    const { currentStep, maximumStep } = this.state;
    const { user, loggedInUser } = this.props;

    return loggedInUser.id === user.id ? (
      <div className={styles.modalContainer}>
        <Header currentStep={currentStep} />
        <TourSteps currentStep={currentStep} />
        <Navigation
          currentStep={currentStep}
          maximumStep={maximumStep}
          onPrev={this.handlePrev}
          onNext={this.handleNext}
        />
      </div>
    ) : (
      <span />
    );
  }
}

PortfolioTour.propTypes = {
  user: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired,
};

PortfolioTour.defaultProps = {
  user: null,
  loggedInUser: null,
  onExit: () => {},
};
