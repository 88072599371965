import React from 'react';

import FormSelect from 'components/FormSelect/FormSelect';
import styles from './DayRateForm.module.scss';

const DayRateForm = ({
  dayRate,
  dayRateCurrency,
  handleDayRateCurrencyChange,
  handleDayRateChange,
}) => {
  return (
    <div>
      <FormSelect
        className={styles.inputs}
        onChange={(currency) => handleDayRateCurrencyChange(currency)}
        options={[
          { label: 'USD ($)', value: 'usd' },
          { label: 'GBP (£)', value: 'gbp' },
        ]}
        value={dayRateCurrency}
      />
      <div className={styles.dayRateContainer}>
        <input
          className={`form-control ${styles.inputs} ${styles.dayRate}`}
          onChange={(event) => handleDayRateChange(event.target.value * 100)}
          placeholder="Eg: 250"
          min="50"
          max="3000"
          type="number"
          value={dayRate > 0 ? dayRate / 100 : ''}
        />
      </div>
      <div>
        {(dayRate / 100 > 3000 || dayRate / 100 < 50) && (
          <p className={`mt+ ${styles.error}`}>
            Please enter a value between 50 and 3000
          </p>
        )}
      </div>
    </div>
  );
};

export default DayRateForm;
