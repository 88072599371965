import React from 'react';
import {
  Button,
  FormControl,
  FormGroup,
  Image,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';

const VerifyUser = ({
  user,
  onValueChanged,
  onClickedVerify,
  verificationUrl,
  currentStep,
}) => {
  const verifiedImage = require('../Badges/Verified/verified.png').default;

  return currentStep === 1 ? (
    <div className="p++ lato-regular">
      <div className="text-center">
        <Image className="center" width={45} src={verifiedImage} />
        <p className="fs-24 lato-bold">Verify your portfolio</p>
      </div>
      <div>
        <p className="fs-16 mt++">
          Being verified is the best way to build trust for clients to hire you.
          A badge will be displayed next to your name.
        </p>
        <p className="mt++ lato-bold">Step 1:</p>
        <p className="mt">
          Add this link to your own website or Twitter bio to prove it's owned
          by you:
        </p>
        <FormGroup bsSize="small" style={{ position: 'relative' }}>
          <FormControl
            id="verify-me-profile-url"
            type="text"
            defaultValue={user.links.main}
          />
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="top"
            overlay={<Popover id="verify-copied-popover">Copied!</Popover>}
          >
            <Button
              style={{
                position: 'absolute',
                top: '3px',
                right: '3px',
                bottom: '3px',
                padding: '2px',
                width: '20%',
              }}
              bsStyle="info"
              bsSize="small"
              onClick={() => {
                document.getElementById('verify-me-profile-url').select();
                document.execCommand('copy');
              }}
            >
              Copy link
            </Button>
          </OverlayTrigger>
        </FormGroup>
        <p className="mt+ lato-bold">Step 2:</p>
        <p className="mt">Enter the URL of your own website or Twitter</p>
        <FormGroup bsSize="small">
          <FormControl
            id="verify-me-url"
            type="text"
            placeholder="https://twitter.com/..."
            value={verificationUrl}
            onChange={(event) => onValueChanged(event.target.value)}
          />
        </FormGroup>
        <div className="mt++ mb text-center">
          <Button
            bsStyle="success btn-lg"
            onClick={() => onClickedVerify(user, verificationUrl)}
          >
            Verify
          </Button>
        </div>
      </div>
      <hr />
      <p className="mt++ mb">
        <a
          href={
            'https://jointwine.typeform.com/to/Ic4EWc?username=' +
            encodeURI(user.username) +
            '&id=' +
            encodeURI(user.id)
          }
          target="_blank"
          rel="noreferrer"
        >
          Click here if it is not working.
        </a>
        &nbsp;Note Facebook, Instagram and LinkedIn do not work, so try another
        service or&nbsp;
        <a
          href="https://help.twine.net/en/category/freelancers-1pt5jzn/"
          target="_blank"
          rel="noreferrer"
        >
          click here
        </a>
        &nbsp;for more help.
      </p>
    </div>
  ) : (
    <div className="p++ lato-regular text-center">
      <Image className="center" width={45} src={verifiedImage} />
      <p className="fs-24 lato-bold">Almost there...</p>
      <p>
        Your link is being verified.{' '}
        <strong>Add your location and a profile picture</strong> to your
        portfolio to make sure your new verified badge appears next to your name
        across Twine.
      </p>
      <p className="aside-text">
        (Please note it may take a few minutes for this to update across the
        site...)
      </p>
      <Button bsStyle="primary" href={user.links.main + '?edit=1'}>
        Go to your portfolio
      </Button>
    </div>
  );
};

export default VerifyUser;
