import React from 'react';
import { Button, Media, Radio } from 'react-bootstrap';
import SvgIcon from 'components/SvgIcon/SvgIcon';

const HireUser = ({
  user,
  suggestedRoles,
  suggestedSkills,
  onRoleSelect,
  onNext,
  onHide,
}) => {
  if (!user) {
    return null;
  }
  return (
    <div className="lato-regular">
      <Media style={{ padding: 0 }}>
        <span
          style={{
            position: 'absolute',
            top: '11px',
            right: '12px',
            zIndex: '1',
          }}
          className="pointer"
          onClick={() => onHide()}
        >
          <SvgIcon size="20px" icon="close" />
        </span>
        <Media.Body style={{ textAlign: 'center' }}>
          <p className="mt++ fs-24 lato-bold">Contact {user.displayname}</p>
          <hr />
          <p className="mt++">
            Tell us a few details and we'll invite {user.displayname} to work on
            your project.
          </p>
          <div className="mb++ center">
            {user && user.top_roles.length > 0 ? (
              <div style={{ marginLeft: '30%' }}>
                <p
                  className="fs-16 lato-bold mt mb"
                  style={{ textAlign: 'left' }}
                >
                  What service do you need:
                </p>
                <div>
                  {user.top_roles.map((role, key) => {
                    let featuredRoleObject = suggestedRoles.find(
                      (roleObj) => roleObj.name === role
                    );
                    if (featuredRoleObject === undefined) {
                      featuredRoleObject = suggestedSkills.find(
                        (skillObj) => skillObj.name === role
                      );
                    }
                    if (featuredRoleObject) {
                      return (
                        <Radio
                          key={key + role}
                          style={{ textAlign: 'left' }}
                          onClick={() => {
                            onRoleSelect(featuredRoleObject);
                            onNext(user, role);
                          }}
                        >
                          {role}
                        </Radio>
                      );
                    }
                  })}
                </div>
              </div>
            ) : (
              <Button
                onClick={() => onNext(user, '')}
                bsStyle="primary"
                className="mt mb"
              >
                Get started
              </Button>
            )}
          </div>
          <div style={{ display: 'table', margin: '15px auto' }}>
            <span
              onClick={() => onHide()}
              className="pointer"
              style={{
                display: 'table-cell',
                verticalAlign: 'middle',
                padding: '0 10px',
                textDecoration: 'underline',
                color: '#9A9A9A',
              }}
            >
              Cancel
            </span>
          </div>
        </Media.Body>
      </Media>
    </div>
  );
};

export default HireUser;
