import React from 'react';

import Icon from 'components/Icon/Icon';
import Loader from 'components/Loader/Loader';
import Testimonial from 'components/Testimonials/Testimonial/Testimonial';

const UserTestimonials = ({
  total,
  loadingTestimonials,
  loggedInUser,
  more,
  onLoadMore,
  onAddTestimonial,
  testimonials,
  user,
}) => {
  const emptyTestimonials =
    require('static/images/portfolios/empty-testimonials-illustration.png').default;

  return (
    <div>
      {!loadingTestimonials && loggedInUser && loggedInUser.id === user.id && (
        <h3 className="lato-bold text-center mb mt--">Your testimonials</h3>
      )}
      {!loadingTestimonials && (!loggedInUser || loggedInUser.id !== user.id) && (
        <h3 className="lato-bold text-center mb mt--">
          {total > 0 ? total + ' ' : ''}Testimonial{total !== 1 && 's'}
        </h3>
      )}
      {testimonials.map((testimonial, idx) => {
        return <Testimonial testimonial={testimonial} key={idx} user={user} />;
      })}
      {more && !loadingTestimonials && (
        <div className="text-center">
          <button
            className="btn btn-info btn-sm mt"
            onClick={() => onLoadMore()}
          >
            Load more
          </button>
        </div>
      )}
      <Loader loading={loadingTestimonials} />
      {!loadingTestimonials && testimonials.length === 0 && (
        <div className="text-center lato-regular">
          <img alt="Twine" src={emptyTestimonials} />
          {!loadingTestimonials &&
          loggedInUser &&
          loggedInUser.id === user.id ? (
            <p className="mt++">
              Add testimonials or reviews so new clients can check your work
              history.
            </p>
          ) : (
            <p className="mt++">{user.displayname} has no testimonials yet</p>
          )}
          {!loadingTestimonials &&
            loggedInUser &&
            loggedInUser.id === user.id && (
              <p className="mt">
                You currently have {testimonials.length} testimonials, so why
                not invite people you've worked with to write a testimonial.
              </p>
            )}
        </div>
      )}
      {!loadingTestimonials && loggedInUser && loggedInUser.id === user.id && (
        <div className="text-center">
          <hr style={{ width: '100%' }} />
          <button
            onClick={onAddTestimonial}
            className="btn btn-primary lato-bold"
          >
            Add a new testimonial
            <Icon
              icon="&#xe62b;"
              style={{ marginLeft: '3px', position: 'relative', top: '2px' }}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default UserTestimonials;
