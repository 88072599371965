import React from 'react';
import styles from './EditableSliderBar.module.scss';
import { Link } from 'react-router-dom';
import RangeSlider from 'components/Shared/Sliders/RangeSlider/RangeSlider';
import SliderBar from 'components/Shared/Sliders/SliderBar/SliderBar';
import Icon from 'components/Icon/Icon';

export const EditableSliderBar = ({
  id,
  experience,
  barName,
  showBar,
  editMode,
  isLast,
  updateFunction,
  linkBarName,
  link,
  allowDelete,
  deleteValue,
  textOptions,
}) => {
  if (!showBar) return <noscript />;
  // Make bar go grey if they have 0 experience
  const barNameClass = experience ? 'black' : 'greyedOut';
  let barTitle;

  // For buyers make the skill names link to the search page
  if (linkBarName) {
    barTitle = <Link to={link}>{barName}</Link>;
  } else {
    // For sellers there is no link
    barTitle = <div className={styles[barNameClass]}>{barName}</div>;
  }
  return (
    <div
      key={id}
      className={styles.experienceRow}
      style={{ marginBottom: isLast ? '0px' : '5px' }}
    >
      <div className={styles.skillName}>{barTitle}</div>
      {editMode ? (
        <div className={styles.rangeSliderContainer}>
          <RangeSlider
            update={(exp, index) => updateFunction(exp, index)}
            index={id}
            sliderValue={experience}
          />
          {allowDelete && (
            <Icon
              icon="&#xe611;"
              className={styles.deleteButton}
              onClick={() => deleteValue(id)}
            />
          )}
        </div>
      ) : (
        <SliderBar textOptions={textOptions} percent={experience} showText />
      )}
    </div>
  );
};
