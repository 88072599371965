import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SelectSelectize from './SelectSelectize';

export default class DiversitySelector extends PureComponent {
  static propTypes = {
    existing: PropTypes.array,
    onSelect: PropTypes.func,
    multi: PropTypes.bool,
  };

  static defaultProps = {
    existing: [],
    multi: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedDiversities:
        props.existing.length > 0
          ? props.existing.map(({ name }) => ({
              label: name,
              value: name,
            }))
          : [],
    };
  }

  componentDidMount() {
    const { selectedDiversities } = this.state;

    if (this.props.onSelect) {
      this.props.onSelect(selectedDiversities);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedDiversities !== this.state.selectedDiversities &&
      this.props.onSelect
    ) {
      this.props.onSelect(this.state.selectedDiversities);
    }
  }

  static options = [
    'Ethnic Origin',
    'Sexuality',
    'Gender',
    'Age',
    'Socioeconomic Status',
    'Disability',
    'Neurodivergence',
  ].map((singleOption) => ({
    value: singleOption,
    label: singleOption,
  }));

  render() {
    const { multi, ...rest } = this.props;

    const { selectedDiversities } = this.state;

    return (
      <SelectSelectize
        {...rest}
        isMulti={multi}
        hideResetButton
        options={DiversitySelector.options}
        values={selectedDiversities}
        onValuesChange={(selectedDiversities) =>
          this.setState({ selectedDiversities })
        }
        placeholder="Eg: Ethnic Origin"
        id={'Selectors-DiversitySelector-diversitySearch'}
      />
    );
  }
}
