import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { withEditFunctionality } from 'components/Portfolio/PortfolioAbout/components/hoc/withEditFunctionality';
import RolesSelector from 'components/Shared/Selectors/RolesSelector';
import LinkOrSpan from 'components/Shared/LinkOrSpan';
import { toPlural, toSeoUrl } from 'helpers/HelperFunctions';
import userShape from 'propTypes/userShape';
import styles from './Roles.module.scss';
import displayNameStyles from './DisplaynameSection.module.scss';

const { item } = displayNameStyles;

@withEditFunctionality
export default class Roles extends Component {
  static propTypes = {
    data: PropTypes.array,
    editMode: PropTypes.bool,
    updateData: PropTypes.func,
    isOwner: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    user: userShape,
  };

  render() {
    const {
      data: roles,
      editMode,
      isOwner,
      toggleEditMode,
      updateData,
      user: { top_roles: topRoles },
    } = this.props;

    return (
      <div
        id="portfolio-roles"
        className={styles.selectorContainer}
        onClick={isOwner && !editMode ? toggleEditMode : null}
        style={{ marginRight: '5px' }}
      >
        {editMode ? (
          <RolesSelector
            existing={roles || []}
            onSelect={(selected) =>
              updateData(selected.map(({ value }) => value))
            }
            maxValues={5}
            styles={{
              control: (base) => ({ ...base, minWidth: '150px' }),
            }}
          />
        ) : (
          <div>
            {topRoles.length > 0 ? (
              <Fragment>
                {topRoles.map((role, key, arr) => (
                  <span key={`PortfolioHeader-roles-${key}`}>
                    {key < 3 && (
                      <LinkOrSpan
                        active={!isOwner}
                        spanProps={{
                          className: item,
                          key,
                        }}
                        linkProps={{
                          className: item,
                          key,
                          to: `/find/${toSeoUrl(toPlural(role))}`,
                        }}
                      >
                        {role}
                        {arr.length - 1 === key ? null : ', '}
                      </LinkOrSpan>
                    )}
                  </span>
                ))}

                {topRoles.length > 3 && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Popover id="other-user-roles" placement="bottom">
                        <span className="lato-bold">Other roles:</span>{' '}
                        {topRoles[3]}
                        {topRoles.length == 5 && (
                          <Fragment>, {topRoles[4]}</Fragment>
                        )}
                      </Popover>
                    }
                  >
                    <span className={item}> +{topRoles.length - 3}</span>
                  </OverlayTrigger>
                )}
              </Fragment>
            ) : (
              <div className={styles.addRoles} onClick={toggleEditMode}>
                Add your roles
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
