import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import Panel from 'components/Panel/Panel';
import Edit from '../edit/Edit';
import styles from './hoc.module.scss';

const { inHeader, header, headerContainer, panel } = styles;

class PortfolioHOC extends PureComponent {
  static propTypes = {
    // Required Props
    ChildComponent: PropTypes.func.isRequired,
    userData: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    user: PropTypes.object.isRequired,
    isOwner: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    title: PropTypes.string,

    // Optional Props
    isHeader: PropTypes.bool,
    updateKey: PropTypes.string,
    placeholder: PropTypes.string,
    loggedInUser: PropTypes.object,
    componentID: PropTypes.string, // Used to identify components that have a duplicated updateKey
    optionalFunction: PropTypes.func,
    disableEditMode: PropTypes.bool,
    permanentInformation: PropTypes.object,
  };

  static defaultProps = {
    disableEditMode: false,
  };

  constructor(props) {
    super(props);

    this.defaultState = {
      editMode: false,
      [props.updateKey]: props.userData,
    };

    this.state = cloneDeep(this.defaultState);
  }

  componentDidUpdate(prevProps) {
    const { userData: oldUserData } = prevProps;
    const { userData: newUserData } = this.props;
    if (!isEqual(oldUserData, newUserData)) this.updateData(newUserData);
  }

  updateData = (newUserData) => {
    const { updateKey } = this.props;

    this.defaultState = { ...this.defaultState, [updateKey]: newUserData };

    this.setState({
      [updateKey]: cloneDeep(newUserData),
    });
  };

  toggleEditMode = () => this.setState({ editMode: !this.state.editMode });

  cancelEdit = () => this.setState(cloneDeep(this.defaultState));

  render() {
    const {
      isHeader,
      user,
      title,
      loggedInUser,
      placeholder,
      isEmpty,
      ChildComponent,
      updateKey,
      optionalFunction,
      disableEditMode,
      isOwner,
      componentID,
      permanentInformation,
    } = this.props;
    const { editMode } = this.state;
    const userData = this.state[updateKey];

    const headerChild = (
      <div id={`portfolio-${componentID || updateKey}`} className={inHeader}>
        <ChildComponent
          data={userData}
          editMode={editMode}
          updateData={(data) => this.setState({ [updateKey]: data })}
          user={user}
          isOwner={isOwner}
          toggleEditMode={this.toggleEditMode}
          loggedInUser={loggedInUser}
          optionalFunction={optionalFunction}
        />
        {!disableEditMode && (
          <Edit
            isHeader
            cancelEdit={this.cancelEdit}
            editMode={editMode}
            updateKey={updateKey}
            componentID={componentID}
            toggleEditMode={this.toggleEditMode}
            updates={{ [updateKey]: userData }}
            owner={user}
          />
        )}
      </div>
    );

    const bodyChild = (
      <Panel id={`portfolio-${componentID || updateKey}`} className={panel}>
        <div className={headerContainer}>
          <h3 className={header} style={{ marginTop: '5px' }}>
            {title}
          </h3>
          {!disableEditMode && (
            <Edit
              cancelEdit={this.cancelEdit}
              editMode={editMode}
              updateKey={updateKey}
              componentID={componentID}
              toggleEditMode={this.toggleEditMode}
              updates={{ [updateKey]: userData }}
              owner={user}
            />
          )}
        </div>
        {permanentInformation && permanentInformation.body}
        {isEmpty && !editMode && !disableEditMode ? (
          placeholder
        ) : (
          <ChildComponent
            data={userData}
            editMode={editMode}
            updateData={(data) => this.setState({ [updateKey]: data })}
            user={user}
            isOwner={isOwner}
            loggedInUser={loggedInUser}
            optionalFunction={optionalFunction}
          />
        )}
      </Panel>
    );

    return !isOwner && isEmpty ? null : isHeader ? headerChild : bodyChild;
  }
}

export { PortfolioHOC };
export default PortfolioHOC;
