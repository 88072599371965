import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { load as _loadSuggestedSkills } from 'redux/modules/suggestedSkills2';

import SelectSelectize from './SelectSelectize';

const SkillsSelector = ({ existing, onSelect }) => {
  const dispatch = useDispatch();

  const {
    loadedSuggestedSkills,
    loadingSuggestedSkills,
    suggestedSkills,
    skillEntities,
  } = useSelector(
    ({
      suggestedSkills2: {
        loaded: loadedSuggestedSkills,
        loading: loadingSuggestedSkills,
        suggestedSkills,
      },
      entities: { skills: skillEntities },
    }) => ({
      loadedSuggestedSkills,
      loadingSuggestedSkills,
      suggestedSkills,
      skillEntities,
    }),
    shallowEqual
  );

  const loadSuggestedSkills = useCallback(
    () => dispatch(_loadSuggestedSkills()),
    [dispatch]
  );

  const [selectedSkills, setSelectedSkills] = useState(
    existing.length > 0
      ? existing.map((skill) => ({ label: skill.name, value: skill.name }))
      : []
  );

  const suggestedSkillEntities = useMemo(
    () =>
      suggestedSkills.length
        ? suggestedSkills.map((skillId) => skillEntities[skillId])
        : [],
    [skillEntities, suggestedSkills]
  );

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedSkills);
    }
  }, []);

  useEffect(() => {
    if (!loadingSuggestedSkills && !loadedSuggestedSkills) {
      loadSuggestedSkills();
    }
  }, [loadingSuggestedSkills, loadedSuggestedSkills]);

  const handleChange = useCallback(
    (selected) => {
      if (onSelect) onSelect(selected);
      return setSelectedSkills(selected);
    },
    [onSelect, setSelectedSkills]
  );

  return (
    <SelectSelectize
      isMulti
      hideResetButton
      options={suggestedSkillEntities.map((skillOption) => ({
        label: skillOption.name_original,
        value: skillOption.name,
      }))}
      values={selectedSkills}
      onValuesChange={handleChange}
      placeholder="Eg: Brochures"
      id={'selectors-SkillsSelector-skillSearch'}
    />
  );
};

SkillsSelector.propTypes = {
  existing: PropTypes.array,
  onSelect: PropTypes.func,
};

SkillsSelector.defaultProps = {
  existing: [],
};

export default memo(SkillsSelector);
