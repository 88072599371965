import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import { Link } from 'react-router-dom';
import Thumbnail from 'components/Thumbnails/Thumbnail';

import Panel from 'components/Panel/Panel';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import DisplaynameSection from './components/DisplaynameSection';
import HireButton from './components/Buttons/HireButton';
import ShortlistButton from './components/Buttons/ShortlistButton';
import BioSnippet from './components/BioSnippet';
import styles from './PortfolioHeader.module.scss';

const {
  carouselButton,
  previous,
  chevron,
  displayname,
  avatar,
  next,
  bioSnippet,
  bioSnippetXS,
  bottomRow,
  buttonContainer,
  buttonContainerXS,
  coverContent,
  portfolioCover,
  topRow,
  xsPanel,
} = styles;

export default class PortfolioHeader extends PureComponent {
  static propTypes = {
    editMode: PropTypes.bool,
    editDisplayname: PropTypes.string,
    existingBriefs: PropTypes.array,
    loggedInUser: PropTypes.object,

    nextUser: PropTypes.oneOf([PropTypes.object, PropTypes.bool]),
    prevUser: PropTypes.oneOf([PropTypes.object, PropTypes.bool]),

    shortlistState: PropTypes.bool,
    toggleShortlist: PropTypes.func,

    verificationState: PropTypes.bool,

    onAccept: PropTypes.func,
    onHire: PropTypes.func,
    onVerify: PropTypes.func,
    onHireByDay: PropTypes.func,
    onRatings: PropTypes.func,
    onUpdateDisplayname: PropTypes.func,
    onUpdateRoles: PropTypes.func,
    onUpdateAvatar: PropTypes.func,
    onUpdateCover: PropTypes.func,
    pitches: PropTypes.oneOf([PropTypes.array, PropTypes.bool]),
    user: PropTypes.object,
  };

  state = {
    avatarEditMode: true,
    coverEditMode: true,
  };

  render() {
    const {
      // editMode,
      existingBriefs,
      loggedInUser,
      nextUser,
      onAccept,
      onHire,
      onHireByDay,
      onRatings,
      onUpdateAvatar,
      onUpdateCover,
      pitches,
      prevUser,
      user,
      onVerify,
      shortlistState,
      toggleShortlist,
      verificationState,
    } = this.props;

    const desktopStyle = {
      filter: 'drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25))',
    };

    const currentUser = loggedInUser && user && loggedInUser.id === user.id;

    const landingBanner = (nextUser || prevUser) && (
      <div
        className="text-center"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          width: '100%',
          position: 'relative',
          height: '55px',
        }}
      >
        {prevUser && (
          <Link
            to={prevUser.links.main_relative}
            className={`${carouselButton} ${previous}`}
          >
            <SvgIcon
              className={chevron}
              icon="chevron-left"
              size="16"
              color="#fff"
            />
            <p className={displayname}>{prevUser.displayname}</p>
            <Thumbnail
              thumbnails={prevUser.avatars}
              className={avatar}
              circle
            />
          </Link>
        )}
        {nextUser && (
          <Link
            to={nextUser.links.main_relative}
            className={`${carouselButton} ${next}`}
          >
            <Thumbnail
              thumbnails={nextUser.avatars}
              circle
              className={avatar}
            />
            <p className={displayname}>{nextUser.displayname}</p>
            <SvgIcon
              className={chevron}
              icon="chevron-right"
              size="16"
              color="#fff"
            />
          </Link>
        )}
        <HireButton
          onAccept={onAccept}
          currentUser={currentUser}
          existingBriefs={existingBriefs}
          onHire={onHire}
          onHireByDay={onHireByDay}
          loggedInUser={loggedInUser}
          pitches={pitches}
          user={user}
        />
      </div>
    );

    const mobilePanel = (
      <Panel className={xsPanel}>
        <BioSnippet
          tagName="h3"
          className={bioSnippetXS}
          lines={4}
          user={user}
        />
        <div className={buttonContainerXS}>
          {loggedInUser && !currentUser && (
            <ShortlistButton
              mobile
              shortlistState={shortlistState}
              toggleShortlist={toggleShortlist}
              style={{ marginBottom: '1em' }}
            />
          )}
          <HireButton
            onAccept={onAccept}
            currentUser={currentUser}
            existingBriefs={existingBriefs}
            onHire={onHire}
            onHireByDay={onHireByDay}
            loggedInUser={loggedInUser}
            pitches={pitches}
            user={user}
            style={{ width: '100%' }}
          />
        </div>
      </Panel>
    );

    return (
      <div>
        <Grid
          fluid
          style={{ width: '100%', padding: 0 }}
          className={'portfolioGrid'}
        >
          {landingBanner}
          {/* <div className="lato-regular" style={{ display: 'flex' }}> */}
          <div
            id="portfolio-header"
            className={portfolioCover}
            style={{
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              position: 'relative',
              width: '100%',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
          >
            {user && (
              <Thumbnail
                className={portfolioCover}
                thumbnails={user.covers}
                id="portfolio-cover"
                style={{
                  height: '100%',
                  position: 'absolute',
                  cursor: currentUser ? 'pointer' : 'initial',
                }}
                onClick={currentUser && onUpdateCover}
                boxShadow="rgb(0 0 0 / 40%) 0px 0px 0px 2000px inset"
              />
            )}
            <div className={coverContent}>
              <div className={topRow}>
                {/* edit button */}
                {/* {currentUser && (
                  <Fade in={coverEditMode} mountOnEnter unmountOnExit>
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                      }}
                      className="pointer"
                    >
                      <Button
                        // onClick={onUpdateAvatar}
                        onClick={onUpdateCover}
                        className={styles.editButton}
                        children={<SvgIcon icon="pencil" color="#ffffff" />}
                      />
                    </div>
                  </Fade>
                )} */}
                <div>
                  {user ? (
                    <Thumbnail
                      thumbnails={user.avatars}
                      className="portfolio-avatar left"
                      id="portfolio-avatar"
                      onClick={currentUser && onUpdateAvatar}
                      style={{
                        position: 'relative',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        cursor: currentUser ? 'pointer' : 'initial',
                      }}
                      circle
                    />
                  ) : (
                    <div
                      className="portfolio-avatar left"
                      onClick={onUpdateAvatar}
                      style={{ borderRadius: '20%', overflow: 'hidden' }}
                    />
                  )}
                  {/* edit button */}
                  {/* {currentUser && (
                    <Fade in={avatarEditMode} mountOnEnter unmountOnExit>
                      <div
                        className="portfolio-avatar--edit left pointer"
                        onClick={onUpdateAvatar}
                      >
                        <SvgIcon icon="pencil" color="#ffffff" />
                      </div>
                    </Fade>
                  )} */}
                </div>
                <BioSnippet
                  tagName="h1"
                  lines={3}
                  className={`text-white ${bioSnippet}`}
                  user={user}
                />
              </div>
              <div className={bottomRow}>
                <DisplaynameSection
                  isOwner={currentUser ? true : false}
                  onRatings={onRatings}
                  user={user}
                />
                <div className={buttonContainer}>
                  {loggedInUser && !currentUser && (
                    <ShortlistButton
                      shortlistState={shortlistState}
                      toggleShortlist={toggleShortlist}
                      style={desktopStyle}
                    />
                  )}
                  {!currentUser && (
                    <HireButton
                      onAccept={onAccept}
                      currentUser={currentUser}
                      existingBriefs={existingBriefs}
                      onHire={onHire}
                      onHireByDay={onHireByDay}
                      loggedInUser={loggedInUser}
                      pitches={pitches}
                      user={user}
                      style={desktopStyle}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Grid>
        {mobilePanel}
      </div>
    );
  }
}
