import React from 'react';
import { Button, DropdownButton, MenuItem } from 'react-bootstrap';

const HireButton = ({
  user,
  loggedInUser,
  currentUser,
  pitches,
  onAccept,
  existingBriefs,
  onHire,
  onHireByDay,
  style,
}) => {
  const getName = user
    ? user.displayname.length > 15
      ? 'freelancer'
      : user.displayname
    : 'me';

  const hasPitchesButton =
    pitches &&
    (pitches.length > 1 ? (
      <DropdownButton
        title={'Hire ' + getName}
        id="acceptme"
        bsStyle="primary"
        bsSize="large"
        style={style}
      >
        {pitches.map((pitch, key) => (
          <MenuItem
            key={key}
            onClick={() => onAccept(pitch.message)}
            eventKey={key}
          >
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {pitch.message.text}
            </div>
          </MenuItem>
        ))}
      </DropdownButton>
    ) : (
      <Button
        onClick={() => onAccept(pitches[0].message)}
        bsStyle="primary"
        bsSize="large"
        style={style}
      >
        Hire {getName}
      </Button>
    ));

  const hasMoreThanOneBriefsButton = existingBriefs &&
    typeof existingBriefs[0] === 'object' &&
    existingBriefs[0].hasOwnProperty('text') && (
      <DropdownButton
        title={'Invite ' + getName}
        id="hireme"
        bsStyle="primary"
        bsSize="large"
        style={style}
      >
        <MenuItem eventKey="1" onClick={() => onHireByDay(null)}>
          Pay for ongoing work
        </MenuItem>
        <MenuItem divider />
        <MenuItem eventKey="2" onClick={() => onHire(null)}>
          New Project Brief
        </MenuItem>
        <MenuItem divider />
        <MenuItem header eventKey="3">
          Invite to an existing brief
        </MenuItem>
        {existingBriefs.map((brief, key) => (
          <MenuItem key={key} onClick={() => onHire(brief)} eventKey={key}>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px',
              }}
            >
              {brief.text}
            </div>
          </MenuItem>
        ))}
      </DropdownButton>
    );

  const hasOneExistingBriefButton = existingBriefs &&
    typeof existingBriefs[0] === 'object' &&
    existingBriefs[0].hasOwnProperty('text') && (
      <Button
        onClick={() => onHire(existingBriefs[0])}
        bsStyle="primary"
        bsSize="large"
        style={style}
      >
        Invite
      </Button>
    );

  // Show the button if the user is looking at a freelancers portfolio, and it is not their own
  const showButton = user && !currentUser && user.type === 'seller';
  const hasPitches = pitches && pitches.length > 0;
  const hasOneExistingBrief = existingBriefs && existingBriefs.length === 1;
  const hasMoreThanOneExistingBriefs =
    existingBriefs && existingBriefs.length >= 2;

  return showButton ? (
    hasPitches ? (
      hasPitchesButton
    ) : hasOneExistingBrief ? (
      hasOneExistingBriefButton
    ) : hasMoreThanOneExistingBriefs ? (
      hasMoreThanOneBriefsButton
    ) : loggedInUser ? (
      <DropdownButton
        title={'Contact ' + getName}
        id="hireme"
        bsSize="large"
        bsStyle="primary"
        style={style}
      >
        <MenuItem divider />
        <MenuItem eventKey="1" onClick={() => onHireByDay(null)}>
          Pay for ongoing work
        </MenuItem>
        <MenuItem eventKey="1" onClick={() => onHire(null)}>
          New Project brief
        </MenuItem>
      </DropdownButton>
    ) : (
      <Button
        onClick={() => onHire(null)}
        bsStyle="primary"
        bsSize="large"
        style={style}
      >
        Contact {getName}
      </Button>
    )
  ) : (
    <noscript />
  );
};

export default HireButton;
