import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactStars from 'react-stars';
import moment from 'moment';
import Verified from 'components/Badges/Verified/Verified';
import Shiitake from 'components/Shiitake/Shiitake';
import styles from './Testimonial.module.scss';

export default class Testimonial extends Component {
  static propTypes = {
    testimonial: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    testimonialKey: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      fullTestimonial: false,
      testimonialTooLarge: false,
    };
  }

  componentDidMount() {
    this.checkTestimonialHeight();
  }

  onViewMore = () => {
    this.setState({
      fullTestimonial: !this.state.fullTestimonial,
    });
  };

  checkTestimonialHeight = () => {
    const testimonialWrapper = document.getElementById(
      'testimonialWrapper_' + this.props.testimonial.id
    );

    this.setState({
      testimonialTooLarge: testimonialWrapper
        ? testimonialWrapper.scrollHeight > 85
        : false,
    });
  };

  render() {
    const { testimonialKey, testimonial, user } = this.props;
    const { fullTestimonial, testimonialTooLarge } = this.state;

    return (
      <div key={`${testimonialKey}innerDiv`}>
        <span>
          <ReactStars count={5} value={testimonial.rating} edit={false} />
          {moment(testimonial.created_at).format('MMM YYYY')}
          <br className="visible-xs" />
          <span className="lato-bold">
            {' '}
            by {testimonial.name + '  '}
            {testimonial.author_id ? (
              <Verified
                user={user}
                style={{ verticalAlign: 'middle', marginRight: '10px' }}
              />
            ) : null}
            {testimonial.company_name &&
            testimonial.company_name !== '' &&
            testimonial.company_name !== testimonial.name ? (
              <span>
                <br className="visible-xs" /> from {testimonial.company_name}
              </span>
            ) : (
              <noscript />
            )}
            {testimonial.project_name && testimonial.project_name !== '' ? (
              <p
                className="lato-regular"
                style={{ color: '#777', fontSize: '14px' }}
              >
                {testimonial.project_name}
              </p>
            ) : (
              <noscript />
            )}
            <div
              className="lato-regular"
              style={{
                maxHeight: fullTestimonial ? '1000px' : '130px',
                overflow: 'hidden',
              }}
            >
              <div
                id={'testimonialWrapper_' + testimonial.id}
                className={styles.testimoinalWrapper}
              >
                <div className={styles.text}>
                  {testimonialTooLarge ? (
                    <div>
                      {!fullTestimonial ? (
                        <Shiitake lines={3}>{testimonial.text}</Shiitake>
                      ) : (
                        testimonial.text
                      )}
                      <span
                        onClick={() => this.onViewMore()}
                        className="text-primary pointer"
                        style={{ marginBotton: '10px' }}
                      >
                        {fullTestimonial ? ' Show less' : 'Show more'}
                      </span>
                    </div>
                  ) : (
                    testimonial.text
                  )}
                </div>
              </div>
            </div>
          </span>
        </span>
      </div>
    );
  }
}
