import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './SliderBar.module.scss';

// This is a reusable component
// It has been designed to be flexible and reusable
export default class SlideBar extends Component {
  static propTypes = {
    // Declare wether you want text to show in the bars
    showText: PropTypes.bool,
    // textOptions (Optional):
    // An object containing:
    // Keys -> Breakpoint int values
    // Values -> Associated text/string
    // If percent is greater than that breakpoint value
    // The associated text/string will render in the bar if
    textOptions: PropTypes.object,
    // Color of the text
    textColor: PropTypes.string,
    // Pass in the percentage filled value of the bar slider
    percent: PropTypes.number,
    // Pass in the color of the bar
    color: PropTypes.string,
  };

  static defaultProps = {
    showText: false,
    color: '#2cae76',
    textColor: '#FFFFFF',
    // Here is the an example of textOptions
    // This default value is used for the ExperienceBars
    textOptions: {
      0: '',
      1: 'Beginner',
      33: 'Intermediate',
      66: 'Expert',
    },
  };

  getBarInnerText() {
    const { percent, textOptions, showText } = this.props;
    // If showText is false return an empty string for the innerText
    if (!showText) return '';
    let innerText;
    // Iterate through the textOptions object
    for (const [num, text] of Object.entries(textOptions)) {
      // If the percentage is >= the number key
      // Update the innerText to the associated value
      if (percent >= num) innerText = text;
    }
    return innerText;
  }

  render() {
    const { percent, color, textColor } = this.props;
    const percentage = percent + '%';
    const innerText = this.getBarInnerText();
    const innerTextColor = percent ? textColor : color;

    return (
      <div className={styles.bar}>
        <div
          className={styles.barInside}
          style={{
            width: percentage,
            color: innerTextColor,
            backgroundColor: color,
          }}
        >
          <div className={styles.barInnerText}>{innerText}</div>
        </div>
      </div>
    );
  }
}
