import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Button, Grid, Row } from 'react-bootstrap';
import Icon from 'components/Icon/Icon';
import Loader from 'components/Loader/Loader';
import ProjectCard from 'components/Cards/ProjectCard/ProjectCard';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import Panel from 'components/Panel/Panel';
import styles from './PortfolioContent.module.scss';

import Skills from './PortfolioAbout/components/ChildComponents/Skills/Skills';
import Experience from './PortfolioAbout/components/ChildComponents/Experience/Experience';
import PortfolioAdverts from './PortfolioAbout/components/ChildComponents/Adverts/PortfolioAdverts';
import { toPlural, toSeoUrl } from 'helpers/HelperFunctions';

const PortfolioContent = ({
  credits,
  isOwn,
  isLoggedIn,
  isReorderingCredits,
  isCreative,
  loadingCredits,
  onReorderCredit,
  user,
  userViewingPage,
  loggedInUser,
  isProjectless,
  partners,
  loadedPartners,
  ...rest
}) => {
  const portfolioAdverts = isLoggedIn &&
    loggedInUser &&
    user &&
    loggedInUser.id === user.id &&
    loadedPartners && (
      <div className={styles.desktopOnly}>
        <PortfolioAdverts
          loadedPartners={loadedPartners}
          partners={partners}
          userLocation={user.location.text}
        />
      </div>
    );

  const fillers = isProjectless && (
    <div className={styles.displayForDesktop}>
      <Skills
        title={'Skills'}
        user={user}
        userData={user.skills}
        updateKey={'skills'}
        isOwner={isOwn}
        isEmpty={user.skills.length === 0}
        placeholder={
          'Add Skills to improve your ranking and attract new clients.'
        }
        loggedInUser={loggedInUser}
      />
      <Experience
        title={'Experience Level'}
        user={user}
        userData={user.skills}
        updateKey={'skills'}
        componentID={'experience'} // Used to identify components that have a duplicated updateKey
        placeholder={
          'Add your experience level once you have added some skills.'
        }
        isEmpty={
          isOwn
            ? user.skills.length === 0
            : user.skills.filter((skill) => skill.experience > 0).length === 0
        }
        isOwner={isOwn}
        loggedInUser={loggedInUser}
      />
    </div>
  );

  const noProjectsPanel =
    isLoggedIn &&
    (isOwn ? (
      <Panel id="portfolio-credits" className={styles.panel}>
        <div
          style={{
            color: '#666',
            margin: '0 auto',
            maxWidth: '640px',
            textAlign: 'center',
          }}
        >
          {!isOwn && (
            <div>
              <p className="lato-regular fs-18">
                To find out more about their experience, post a job and invite
                them to it. Or you can favourite them for any future jobs.
              </p>
            </div>
          )}
          {isOwn &&
            user.stats.num_of_projects === 0 &&
            user.stats.num_of_credits === 0 && (
              <div className={styles.panelChild}>
                <h3 className="lato-bold mb">Add examples of your work</h3>
                <p className="lato-regular fs-18">
                  Clients need to see examples of your work before they hire
                  you.
                </p>
                <p className="lato-regular fs-18">
                  Add work to your portfolio in order to pitch on jobs.
                </p>
                <div>
                  <Button
                    bsStyle="primary"
                    bsSize="lg"
                    className="mt++ lato-bold"
                    href="/projects/create"
                  >
                    <SvgIcon
                      icon="plus_circle"
                      size="18"
                      style={{ top: '1px', position: 'relative' }}
                    />
                    &nbsp;&nbsp;New Project
                  </Button>
                  <br />
                  <Button
                    bsStyle="primary"
                    bsSize="lg"
                    className="mt++ lato-bold"
                    href="/upload"
                  >
                    <SvgIcon
                      icon="upload"
                      size="18"
                      style={{ top: '1px', position: 'relative' }}
                    />
                    &nbsp;&nbsp;New Project with Files
                  </Button>
                </div>
              </div>
            )}
          {isOwn &&
            user.stats.num_of_projects >= 0 &&
            user.stats.num_of_credits === 0 && (
              <div
                style={{
                  color: '#666',
                  margin: '20px auto',
                  textAlign: 'center',
                }}
              >
                <p className="lato-regular">
                  To add or remove projects go to{' '}
                  <a href="/projects/manage">Manage Projects</a>
                </p>
              </div>
            )}
        </div>
      </Panel>
    ) : (
      <Panel className={styles.panel}>
        <div
          style={{
            color: '#666',
            margin: '0 auto',
            maxWidth: '640px',
            textAlign: 'center',
          }}
        >
          <div>
            <p className="lato-regular fs-18">
              To find out more about their experience, post a job and invite
              them to it. Or you can favourite them for any future jobs.
            </p>
          </div>
        </div>
      </Panel>
    ));

  const creditsGrid = (
    <Grid fluid>
      {!loadingCredits &&
        typeof credits[0] === 'object' &&
        credits[0].hasOwnProperty('id') &&
        credits.map((credit, idx) => {
          return (
            <div key={`PortfolioContent-${idx}`}>
              <Row>
                <ProjectCard
                  disabled={isReorderingCredits}
                  isOwn={isOwn}
                  project={credit.project}
                  roles={credit.roles}
                  showMoveToTop={idx > 0}
                  showMoveUp={idx > 0}
                  showMoveDown={idx < credits.length - 1}
                  onMoveToTop={() => onReorderCredit(credit.id, 1)}
                  onMoveUp={() => onReorderCredit(credit.id, idx)}
                  onMoveDown={() => onReorderCredit(credit.id, idx + 2)}
                  userViewingPage={userViewingPage}
                  aspectRatio={'16:9'}
                  user={user}
                />
              </Row>
            </div>
          );
        })}
      {loadingCredits && credits.length > 0 && (
        <div className="mt+ mb+" style={{ clear: 'both' }}>
          {credits.map((credit, idx) => (
            <Row
              xs={12}
              sm={12}
              md={12}
              lg={12}
              key={`portfolio-content-${credit.id}`}
            >
              <div className={styles.projectCard}>
                <ProjectCard
                  disabled={isReorderingCredits}
                  isOwn={isOwn}
                  project={credit.project}
                  roles={credit.roles}
                  showMoveToTop={idx > 0}
                  showMoveUp={idx > 0}
                  showMoveDown={idx < credits.length - 1}
                  onMoveToTop={() => onReorderCredit(credit.id, 1)}
                  onMoveUp={() => onReorderCredit(credit.id, idx)}
                  onMoveDown={() => onReorderCredit(credit.id, idx + 2)}
                  userViewingPage={userViewingPage}
                  aspectRatio={'16:9'}
                  user={user}
                />
              </div>
            </Row>
          ))}
          <Loader loading />
        </div>
      )}
    </Grid>
  );

  const hasProjectInfoPanel = isOwn && user.stats.num_of_credits > 0 && (
    <Panel className={styles.panel} key="project-info-panel">
      <div
        style={{
          color: '#666',
          margin: '10px auto',
          textAlign: 'center',
        }}
      >
        <p className="lato-regular">
          Click{' '}
          <SvgIcon
            icon="pencil"
            size="18"
            style={{ position: 'relative', top: '4px' }}
          />{' '}
          next to each project to change your portfolio order
        </p>
        <p className="lato-regular">
          To add or remove projects go to{' '}
          <a href="/projects/manage">Manage Projects</a>
        </p>
      </div>
    </Panel>
  );

  const hasProjectsCreditsPanel = isOwn &&
    user.stats.num_of_projects > 0 &&
    user.stats.num_of_credits > 0 && (
      <Panel className={styles.panel} key="project-credit-panel">
        <div
          style={{
            color: '#666',
            margin: '0 auto',
            maxWidth: '640px',
            textAlign: 'center',
          }}
        >
          {user.stats.num_of_credits > 0 && user.stats.num_of_credits < 10 && (
            <div className={styles.panelChild}>
              <h3 className="lato-bold mb">Add more examples of your work</h3>
              <p className="lato-regular fs-18">
                Clients need to see examples of your work before they hire you.
              </p>
              <p className="lato-regular fs-18">
                We recommend 9-12 relevant corporate examples for clients.
              </p>
              <div>
                <Button
                  bsStyle="primary"
                  bsSize="lg"
                  className="mt++ lato-bold"
                  href="/projects/create"
                >
                  <SvgIcon
                    icon="plus_circle"
                    size="18"
                    style={{ top: '1px', position: 'relative' }}
                  />
                  &nbsp;&nbsp;New Project
                </Button>
                <br />
                <Button
                  bsStyle="primary"
                  bsSize="lg"
                  className="mt++ lato-bold"
                  href="/upload"
                >
                  <SvgIcon
                    icon="upload"
                    size="18"
                    style={{ top: '1px', position: 'relative' }}
                  />
                  &nbsp;&nbsp;New Project with Files
                </Button>
              </div>
            </div>
          )}
          {user.stats.num_of_credits > 10 && (
            <div className={styles.panelChild}>
              <h3 className="lato-bold mb">Add more work</h3>
              <div>
                <Button
                  bsStyle="primary"
                  bsSize="lg"
                  className="mt++ lato-bold"
                  href="/projects/create"
                >
                  <SvgIcon
                    icon="plus_circle"
                    size="18"
                    style={{ top: '1px', position: 'relative' }}
                  />
                  &nbsp;&nbsp;New Project
                </Button>
                <br />
                <Button
                  bsStyle="primary"
                  bsSize="lg"
                  className="mt++ lato-bold"
                  href="/upload"
                >
                  <SvgIcon
                    icon="upload"
                    size="18"
                    style={{ top: '1px', position: 'relative' }}
                  />
                  &nbsp;&nbsp;New Project with Files
                </Button>
              </div>
            </div>
          )}
          {user &&
            !user.pro &&
            (!(user.stats.num_of_projects > user.stats.num_of_credits) ||
              !isCreative) && (
              <div className={styles.panelChild}>
                <h3 className="lato-bold mb">Upgrade to Twine Pro</h3>
                <p className="lato-regular fs-18">
                  More jobs, no transaction fees and more pitches.
                </p>
                <div>
                  <Button
                    bsStyle="success"
                    href="/pricing/creative"
                    bsSize="lg"
                    className="mt++"
                  >
                    Read the reasons to upgrade&nbsp;&nbsp;
                    <Icon
                      icon="&#xe62b;"
                      style={{ top: '1px', position: 'relative' }}
                    />
                  </Button>
                </div>
              </div>
            )}
        </div>
      </Panel>
    );

  const hasProjectsPanels = [hasProjectInfoPanel, hasProjectsCreditsPanel];

  const visitorPanel = !isOwn && (
    <Panel className={styles.panel}>
      <div
        style={{
          color: '#666',
          margin: '0 auto',
          maxWidth: '640px',
          textAlign: 'center',
        }}
      >
        {user && user.pro ? (
          <div className={styles.panelChild}>
            <h3 className="lato-bold mb">Hire {user.displayname} today</h3>
            <p className={'lato-regular fs-18 ' + styles.visitorPanelText}>
              To get started post up your job and then invite {user.displayname}{' '}
              to your job.
            </p>
            <div>
              <Button
                bsStyle="success"
                href={`/post?freelancerId=${user.id}`}
                bsSize="lg"
                className="mt++"
              >
                Contact {user.displayname}
                <Icon
                  icon="&#xe62b;"
                  style={{
                    top: '2px',
                    position: 'relative',
                    marginLeft: '4px',
                  }}
                />
              </Button>
            </div>
            <p className={styles.roleLinks}>
              {user?.featured_roles?.length > 0 &&
                user.featured_roles.sort().map((role, key) => (
                  <div key={`PortfolioHeader-roles-${key}`}>
                    <a
                      href={
                        `/find/${toSeoUrl(toPlural(role))}` +
                        (user.location.country_code !== ''
                          ? '/' + user.location.country_code
                          : '') +
                        (user.location.country_code !== '' &&
                        user.location.city_slug !== ''
                          ? '/' + user.location.city_slug
                          : '')
                      }
                    >
                      {toPlural(role)} for hire{' '}
                      {user.location.city
                        ? 'in ' +
                          user.location.city +
                          (user.location.country
                            ? ', ' + user.location.country
                            : '')
                        : ''}
                    </a>
                  </div>
                ))}
            </p>
          </div>
        ) : (
          <div className={styles.panelChild}>
            <h3 className="lato-bold mb">
              Hire a {user?.featured_roles[0] ?? 'Freelancer'}
            </h3>
            <p className={'lato-regular fs-18 ' + styles.visitorPanelText}>
              We have the best{' '}
              {user?.featured_roles[0]
                ? user.featured_roles[0].toLowerCase()
                : ''}{' '}
              experts on Twine. Hire a{' '}
              {user?.featured_roles[0]
                ? user.featured_roles[0].toLowerCase()
                : 'freelancer'}{' '}
              {user?.location.city ? 'in ' + user.location.city : ''} today.
            </p>
            <div>
              <Button bsStyle="info" href="/post" bsSize="lg" className="mt++">
                {user?.featured_roles[0]
                  ? 'Find a ' + user.featured_roles[0]
                  : 'Find a Freelancer'}
                <Icon
                  icon="&#xe62b;"
                  style={{
                    top: '2px',
                    position: 'relative',
                    marginLeft: '4px',
                  }}
                />
              </Button>
            </div>
            <p className={styles.roleLinks}>
              {user?.featured_roles?.length > 0 &&
                user.featured_roles.sort().map((role, key) => (
                  <div key={`PortfolioHeader-roles-${key}`}>
                    <a
                      href={
                        `/find/${toSeoUrl(toPlural(role))}` +
                        (user.location.country_code !== ''
                          ? '/' + user.location.country_code
                          : '') +
                        (user.location.country_code !== '' &&
                        user.location.city_slug !== ''
                          ? '/' + user.location.city_slug
                          : '')
                      }
                    >
                      {toPlural(role)} for hire{' '}
                      {user.location.city
                        ? 'in ' +
                          user.location.city +
                          (user.location.country
                            ? ', ' + user.location.country
                            : '')
                        : ''}
                    </a>
                  </div>
                ))}
            </p>
          </div>
        )}
      </div>
    </Panel>
  );

  return (
    <Grid fluid {...rest} className="desktopDisplayRightPadding">
      {portfolioAdverts}
      {fillers}
      {!loadingCredits && credits.length === 0 && user
        ? noProjectsPanel
        : creditsGrid}
      {hasProjectsPanels}
      {visitorPanel}
    </Grid>
  );
};

PortfolioContent.propTypes = {
  credits: PropTypes.array,
  isOwn: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isReorderingCredits: PropTypes.bool,
  isCreative: PropTypes.bool,
  loadingCredits: PropTypes.bool,
  onReorderCredit: PropTypes.func,
  user: PropTypes.object,
  userViewingPage: PropTypes.object,
  loggedInUser: PropTypes.object,
  isProjectless: PropTypes.bool,
  partners: PropTypes.array,
  loadedPartners: PropTypes.bool,
};

export default memo(PortfolioContent);
