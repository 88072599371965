import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withEditFunctionality } from 'components/Portfolio/PortfolioAbout/components/hoc/withEditFunctionality';
import styles from './DisplaynameSection.module.scss';

class DisplaynameBase extends Component {
  static propTypes = {
    data: PropTypes.string,
    editMode: PropTypes.bool,
    isOwner: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    updateData: PropTypes.func,
    user: PropTypes.any,
  };

  render() {
    const {
      data: displayName,
      editMode,
      isOwner,
      user,
      updateData,
    } = this.props;

    return editMode ? (
      <span id="portfolio-displayname">
        <input
          style={{
            verticalAlign: 'middle',
            display: 'inline',
            width: 'fit-content',
            height: '36px',
            margin: '0px 10px 0px 0px',
            paddingRight: displayName.length > 30 ? '30px' : '12px',
          }}
          className="form-control mr- lato-bold"
          maxLength="40"
          value={displayName}
          onChange={({ target: { value } }) => updateData(value)}
        />
        {displayName.length > 30 && (
          <span
            style={{
              marginLeft: '-35px',
              marginRight: '20px',
              color: '#aaa',
            }}
          >
            <em>{40 - displayName.length}</em>
          </span>
        )}
      </span>
    ) : (
      <h1
        id="portfolio-displayname"
        className={`${styles.displayName} ${isOwner ? styles.item : ''}`}
        style={{
          verticalAlign: 'middle',
          margin: 0,
          marginTop: '-8px',
        }}
      >
        {user.displayname}
      </h1>
    );
  }
}

const Displayname = withEditFunctionality(DisplaynameBase);

export default Displayname;
