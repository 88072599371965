import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  DropdownButton,
  ListGroupItem,
  MenuItem,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import Thumbnail from 'components/Thumbnails/Thumbnail';
import styles from './ProjectCard.module.scss';
import RoleTag from '../../Tags/RoleTag/RoleTag';
import Shiitake from 'components/Shiitake/Shiitake';
import AlertBox from 'components/AlertBox/AlertBox';
import { useDispatch } from 'react-redux';
import { deleteProject } from 'redux/modules/portfolio';
import { removeMarkdownSyntax } from 'helpers/HelperFunctions';

const ProjectCard = ({
  aspectRatio = null,
  disabled = false,
  featured = false,
  isOwn = false,
  project,
  roles = [],
  showCredits = false,
  showMoveToTop = false,
  showMoveUp = false,
  showMoveDown = false,
  onMoveToTop,
  onMoveUp,
  onMoveDown,
  userViewingPage,
  user,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let icon = 'paper';

  const noBorder = {
    border: 'none',
  };
  if (project && project.collection) {
    icon = 'collection';
  } else if (project && project.mediaType === 'video') {
    icon = 'film';
  } else if (project && project.mediaType === 'audio') {
    icon = 'music';
  } else if (project && project.mediaType === 'image') {
    icon = 'camera';
  }

  const handleConfirm = () => {
    dispatch(deleteProject(project.id));
    setShowDeleteModal(false);
    window.location.href = `/` + user.username;
  };

  return (
    <ul
      className={`list-group pointer ${styles.projectCard}`}
      style={{
        opacity: disabled ? 0.7 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <AlertBox
        show={showDeleteModal}
        title="Delete Project"
        text={'Are you sure you want to delete this project?'}
        confirmButtonText={"Yes I'm sure"}
        onConfirm={() => handleConfirm()}
        onCancel={() => setShowDeleteModal(false)}
      />

      <ListGroupItem className="no-padding" style={{ overflow: 'hidden' }}>
        {featured && (
          <div
            style={{
              backgroundColor: '#0fe2af',
              borderRadius: '4px',
              color: '#fff',
              fontSize: '1.3em',
              height: '30px',
              left: '10px',
              lineHeight: '30px',
              position: 'absolute',
              textAlign: 'center',
              top: '10px',
              width: '30px',
              zIndex: 2,
            }}
          >
            <SvgIcon
              icon="star"
              size="18"
              style={{ position: 'relative', top: '3px' }}
            />
          </div>
        )}
        {project.mediaType !== 'text' && (
          <>
            {isOwn && (
              <div
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  zIndex: 2,
                }}
              >
                <DropdownButton
                  bsSize="sm"
                  bsStyle="info"
                  id={`project-actions-${project.id}`}
                  noCaret
                  onClick={(event) => {
                    event.stopPropagation();
                    return false;
                  }}
                  pullRight
                  style={{
                    height: '30px',
                    lineHeight: '30px',
                    padding: 0,
                    textAlign: 'center',
                    width: '30px',
                  }}
                  title={
                    <SvgIcon
                      icon="dots-three-horizontal"
                      size="18"
                      style={{
                        position: 'relative',
                        top: '4px',
                        pointerEvents: 'none',
                      }}
                    />
                  }
                >
                  <MenuItem key="1" href={`${project.links.edit}`}>
                    Edit
                  </MenuItem>
                  <MenuItem key="2" onClick={() => setShowDeleteModal(true)}>
                    Delete
                  </MenuItem>
                  {(showMoveUp || showMoveDown || showMoveToTop) && (
                    <MenuItem key="3" divider />
                  )}
                  {showMoveToTop && (
                    <MenuItem
                      key="4"
                      onClick={(event) => {
                        onMoveToTop();
                        event.stopPropagation();
                        return false;
                      }}
                    >
                      Move to top
                    </MenuItem>
                  )}
                  {showMoveUp && (
                    <MenuItem
                      key="5"
                      onClick={(event) => {
                        onMoveUp();
                        event.stopPropagation();
                        return false;
                      }}
                    >
                      Move up
                    </MenuItem>
                  )}
                  {showMoveDown && (
                    <MenuItem
                      key="6"
                      onClick={(event) => {
                        onMoveDown();
                        event.stopPropagation();
                        return false;
                      }}
                    >
                      Move down
                    </MenuItem>
                  )}
                </DropdownButton>
              </div>
            )}
            <div
              className={styles.coverImage}
              style={{ height: aspectRatio ? '100%' : '200px' }}
              onClick={() =>
                project && history.push(project.links.main_relative)
              }
            >
              {project && project.thumbnails && (
                <Thumbnail
                  aspectRatio={aspectRatio ? aspectRatio : null}
                  thumbnails={project.thumbnails}
                  style={{ width: '100%', height: '100%' }}
                />
              )}
            </div>
          </>
        )}
      </ListGroupItem>
      <div
        className={`border border-dark`}
        onClick={() => project && history.push(project.links.main_relative)}
      >
        <ListGroupItem
          className="p-"
          style={{
            border: '2px solid white',
            borderRadius: '0px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            className="lato-bold"
            style={{
              fontSize: '1.1em',
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              marginLeft: 8,
            }}
          >
            <SvgIcon
              icon={icon}
              size="18"
              color="#aaa"
              style={{ marginRight: '8px', position: 'relative', top: '2px' }}
            />
            {project && project.title}
            {!project && (
              <div
                style={{
                  backgroundColor: '#eee',
                  display: 'inline-block',
                  height: '12px',
                  width: '60%',
                  margin: '10px 0 3px',
                }}
              />
            )}
          </div>
          {isOwn && project.mediaType === 'text' && (
            <DropdownButton
              bsSize="sm"
              bsStyle="info"
              id={`project-actions-${project.id}`}
              noCaret
              onClick={(event) => {
                event.stopPropagation();
                return false;
              }}
              pullRight
              style={{
                height: '30px',
                lineHeight: '30px',
                padding: 0,
                textAlign: 'center',
                width: '30px',
                zIndex: 2,
              }}
              title={
                <SvgIcon
                  icon="dots-three-horizontal"
                  size="18"
                  style={{
                    position: 'relative',
                    top: '4px',
                    pointerEvents: 'none',
                  }}
                />
              }
            >
              <MenuItem key="1" href={`${project.links.edit}`}>
                Edit
              </MenuItem>
              <MenuItem key="2" onClick={() => setShowDeleteModal(true)}>
                Delete
              </MenuItem>
              {(showMoveUp || showMoveDown || showMoveToTop) && (
                <MenuItem key="3" divider />
              )}
              {showMoveToTop && (
                <MenuItem
                  key="4"
                  onClick={(event) => {
                    onMoveToTop();
                    event.stopPropagation();
                    return false;
                  }}
                >
                  Move to top
                </MenuItem>
              )}
              {showMoveUp && (
                <MenuItem
                  key="5"
                  onClick={(event) => {
                    onMoveUp();
                    event.stopPropagation();
                    return false;
                  }}
                >
                  Move up
                </MenuItem>
              )}
              {showMoveDown && (
                <MenuItem
                  key="6"
                  onClick={(event) => {
                    onMoveDown();
                    event.stopPropagation();
                    return false;
                  }}
                >
                  Move down
                </MenuItem>
              )}
            </DropdownButton>
          )}
        </ListGroupItem>
        {project && project.description && (
          <ListGroupItem style={noBorder}>
            <Shiitake
              lines={project.mediaType === 'text' ? 8 : 2}
              style={{ marginLeft: '10px' }}
            >
              {removeMarkdownSyntax(project.description)}
            </Shiitake>
          </ListGroupItem>
        )}
        <ListGroupItem style={noBorder}>
          <div
            style={{
              fontFamily: 'Lato',
              fontWeight: '700',
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {project &&
              roles.map((role, idx) => {
                return (
                  <RoleTag
                    key={`ProjectCard-RoleTag-${idx}`}
                    role={role}
                    idx={idx}
                    user={userViewingPage}
                  />
                );
              })}
            {!project && (
              <div
                style={{
                  backgroundColor: '#eee',
                  height: '10px',
                  width: '50%',
                  margin: '1px 0',
                }}
              />
            )}
          </div>
        </ListGroupItem>
        {showCredits && project.credits && project.credits.length > 0 ? (
          <ListGroupItem className={styles.creditsList} style={noBorder}>
            {project.credits.map((credit) => (
              <Link
                key={credit.id}
                to={credit.user.links.main_relative}
                onClick={(event) => event.stopPropagation()}
              >
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="userinfo"
                      style={{ fontSize: '14px', fontFamily: 'Lato' }}
                    >
                      <strong>{credit.user.displayname}</strong>
                      <br />
                      <em>
                        {credit.roles.map(
                          (role, idx) =>
                            `${role.name}${
                              idx < credit.roles.length - 1 ? ',' : ''
                            } `
                        )}
                      </em>
                    </Tooltip>
                  }
                >
                  <Thumbnail
                    thumbnails={credit.user.avatars}
                    className={styles.creditAvatar}
                    circle
                  />
                </OverlayTrigger>
              </Link>
            ))}
          </ListGroupItem>
        ) : null}
      </div>
    </ul>
  );
};

ProjectCard.propTypes = {
  aspectRatio: PropTypes.string,
  disabled: PropTypes.bool,
  featured: PropTypes.bool,
  isOwn: PropTypes.bool,
  project: PropTypes.object,
  roles: PropTypes.array,
  showCredits: PropTypes.bool,
  showMoveToTop: PropTypes.bool,
  showMoveUp: PropTypes.bool,
  showMoveDown: PropTypes.bool,
  onMoveToTop: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  userViewingPage: PropTypes.object,
  user: PropTypes.object,
};

export default ProjectCard;
