import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTelephoneInput from 'react-telephone-input';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import { addTestimonial } from 'redux/modules/testimonials';
import Icon from 'components/Icon/Icon';
import flags from 'components/Post/PostContactDetails/flags.png';
import emptyTestimonials from 'static/images/portfolios/empty-testimonials-illustration.png';
import './TestimonialsAdd.scss';

@connect(
  ({ auth: { user }, testimonials: { addingTestimonial, addSuccessful } }) => ({
    user,
    addingTestimonial,
    addSuccessful,
  }),
  {
    addTestimonial,
  }
)
export default class TestimonialsAdd extends Component {
  static propTypes = {
    user: PropTypes.object,
    addingTestimonial: PropTypes.bool,
    addSuccessful: PropTypes.bool,
    addTestimonial: PropTypes.func,
  };

  state = {
    nameDirty: false,
    name: '',
    companyNameDirty: false,
    companyName: '',
    projectNameDirty: false,
    projectName: '',
    emailDirty: false,
    email: '',
    phoneDirty: false,
    phone: '',
  };

  getValidationState(field) {
    const length = this.state[field].length;

    if (this.state[field + 'Dirty'] && length <= 0) {
      return 'error';
    }

    return null;
  }

  handleChangeName(event) {
    this.setState({
      nameDirty: true,
      name: event.target.value,
    });
  }

  handleChangeCompanyName(event) {
    this.setState({
      companyNameDirty: true,
      companyName: event.target.value,
    });
  }

  handleChangeProjectName(event) {
    this.setState({
      projectNameDirty: true,
      projectName: event.target.value,
    });
  }

  handleChangeEmail(event) {
    this.setState({
      emailDirty: true,
      email: event.target.value,
    });
  }

  handleChangePhone(number) {
    this.setState({
      phoneDirty: true,
      phone: number,
    });
  }

  handleAdd() {
    const { name, companyName, projectName, email, phone } = this.state;

    this.props.addTestimonial(name, companyName, projectName, email, phone);
  }

  render() {
    return (
      <div>
        {this.props.addSuccessful ? (
          <div>
            <Row className="mt++ text-center">
              <Col xs={12} sm={6} smPush={3} md={4} mdPush={4}>
                <h1 className="lato-bold fs-18">Thanks!</h1>
              </Col>
            </Row>
            <Row className="mt++ text-center">
              <Col xs={12} sm={6} smPush={3}>
                <p className="lato-regular mt++ mb++">
                  We'll contact your buyer and ask them to post a short
                  testimonial.
                </p>
              </Col>
            </Row>
            <Row className="mt++ text-center">
              <Col xs={12} sm={6} smPush={3}>
                <Link to={this.props.user.links.main_relative}>
                  <Button
                    className="text-center mt++"
                    bsStyle="primary"
                    bsSize="large"
                    block
                  >
                    Back to my profile
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <form>
              <Row>
                <Col className="text-center col-centered lato-regular" xs={11}>
                  <h3 className="lato-bold fs-24">
                    Get hired on more freelance projects
                  </h3>
                  <img alt="Twine" className="mt" src={emptyTestimonials} />
                  <p className="mt++">
                    Add testimonials so new customers can check your work
                    history.
                  </p>
                  <p className="mt++">
                    Invite people you've worked with to write a testimonial:
                  </p>
                </Col>
              </Row>
              <Row className="mt++">
                <Col xs={11} className="col-centered">
                  <FormGroup
                    controlId="testimonialName"
                    validationState={this.getValidationState('name')}
                  >
                    <ControlLabel className="lato-bold fs-14">
                      Buyer name:
                    </ControlLabel>
                    <FormControl
                      type="text"
                      name="fullname"
                      value={this.state.name}
                      placeholder="Eg: Joe Bloggs"
                      onChange={this.handleChangeName.bind(this)}
                      disabled={this.props.addingTestimonial}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={11} className="col-centered">
                  <FormGroup
                    controlId="testimonialCompanyName"
                    validationState={this.getValidationState('companyName')}
                  >
                    <ControlLabel className="lato-bold fs-14">
                      Company name:
                    </ControlLabel>
                    <FormControl
                      type="text"
                      name="name"
                      value={this.state.companyName}
                      placeholder="Eg: Joe Bloggs Coffee Co Ltd"
                      onChange={this.handleChangeCompanyName.bind(this)}
                      disabled={this.props.addingTestimonial}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={11} className="col-centered">
                  <FormGroup
                    controlId="testimonialEmail"
                    validationState={this.getValidationState('email')}
                  >
                    <ControlLabel className="lato-bold fs-14">
                      Buyer email address:
                    </ControlLabel>
                    <FormControl
                      type="text"
                      name="email"
                      value={this.state.email}
                      placeholder="Eg: joebloggs@coffeeco.com"
                      onChange={this.handleChangeEmail.bind(this)}
                      disabled={this.props.addingTestimonial}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={11} className="col-centered">
                  <FormGroup
                    controlId="testimonialPhone"
                    validationState={this.getValidationState('phone')}
                  >
                    <ControlLabel className="lato-bold fs-14">
                      Buyer phone number:
                    </ControlLabel>
                    <ReactTelephoneInput
                      defaultCountry="us"
                      preferredCountries={['us', 'gb', 'in']}
                      flagsImagePath={flags}
                      value={this.state.phone}
                      disabled={this.props.addingTestimonial}
                      onChange={(number) => this.handleChangePhone(number)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={11} className="col-centered">
                  <FormGroup
                    controlId="testimonialProjectName"
                    validationState={this.getValidationState('projectName')}
                  >
                    <ControlLabel className="lato-bold fs-14">
                      What's the name of the project you did:
                    </ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.projectName}
                      placeholder="Eg: Coffee Co Branding Design"
                      onChange={this.handleChangeProjectName.bind(this)}
                      disabled={this.props.addingTestimonial}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt++ text-center">
                <Col xs={11} className="col-centered">
                  <Button
                    style={{ width: '75%' }}
                    className="center"
                    bsStyle="primary"
                    bsSize="large"
                    block
                    onClick={this.handleAdd.bind(this)}
                    disabled={
                      this.props.addingTestimonial ||
                      this.state.name.length <= 0 ||
                      this.state.companyName.length <= 0 ||
                      this.state.projectName.length <= 0 ||
                      this.state.email.length <= 0
                    }
                  >
                    {this.props.addingTestimonial ? (
                      'Sending...'
                    ) : (
                      <span>
                        Send request <Icon icon="&#xe62b;" className="ml" />
                      </span>
                    )}
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </div>
    );
  }
}
