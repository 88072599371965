import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { withEditFunctionality } from 'components/Portfolio/PortfolioAbout/components/hoc/withEditFunctionality';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DiversitySelector from 'components/Shared/Selectors/DiversitySelector';

@withEditFunctionality
export default class Diversity extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    editMode: PropTypes.bool,
    updateData: PropTypes.func,
    user: PropTypes.object,
  };

  updateDiversity = (diversities) => {
    const { updateData } = this.props;
    const diversitiesList = diversities.map((diversity) => diversity.value);
    updateData(diversitiesList);
  };

  renderDiversities = (diversitiesList) =>
    diversitiesList.map((diversity) => <li key={diversity}>{diversity}</li>);

  render() {
    const { data: diversitiesList, editMode, user } = this.props;

    return (
      (diversitiesList.length > 0 || editMode) && (
        <div id="portfolio-livedExperiences">
          {editMode ? (
            <Fragment>
              <div style={{ paddingBottom: 20 }}>
                Please select any applicable categories.
              </div>
              <DiversitySelector
                existing={user.diversity}
                onSelect={this.updateDiversity}
                multi={user.type === 'seller'}
                style={{ width: '100%', marginBottom: '20px' }}
              />
            </Fragment>
          ) : (
            <TransitionGroup>
              <CSSTransition
                classNames="animate"
                timeout={{ enter: 300, exit: 500 }}
              >
                <Fragment>{this.renderDiversities(diversitiesList)}</Fragment>
              </CSSTransition>
            </TransitionGroup>
          )}
        </div>
      )
    );
  }
}
