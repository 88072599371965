import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withEditFunctionality } from 'components/Portfolio/PortfolioAbout/components/hoc/withEditFunctionality';
import SkillsSelector from 'components/Shared/Selectors/SkillsSelector';
import styles from './Skills.module.scss';
import SkillsTiles from './SkillsTiles/SkillsTiles';

const rowLengthObj = {
  0: 1,
  235: 2,
  320: 3,
  400: 4,
  470: 5,
  600: 6,
  700: 7,
  990: 5,
  1200: 6,
};
@withEditFunctionality
export default class Skills extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    editMode: PropTypes.bool,
    updateData: PropTypes.func,
    loggedInUser: PropTypes.object,
    isOwner: PropTypes.bool,
  };

  state = {
    showMore: false,
    rowLength: 5,
  };

  componentDidMount() {
    this.updateRowLength();
    this.updateOnResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateRowLength, false);
  }

  updateOnResize() {
    window.addEventListener('resize', this.updateRowLength, false);
  }

  updateRowLength = () => {
    let { rowLength } = this.state;
    const windowWidth = window.innerWidth;
    for (const [width, length] of Object.entries(rowLengthObj)) {
      if (windowWidth > width) rowLength = length;
    }
    this.setState({ rowLength });
  };

  updateSkills(newSkills) {
    const { data: skills, updateData: updateSkills } = this.props;
    const updatedSkills = [];
    const cache = {};

    // Save the old skill names in the cache
    for (const skill of skills) cache[skill.name] = skill;

    for (const newSkill of newSkills) {
      // If the skill already exists
      if (cache[newSkill.label]) {
        // Save the old skill object
        updatedSkills.push(cache[newSkill.label]);
      } else {
        // Otherwise create a new skill object
        updatedSkills.push({ name: newSkill.label, experience: 0 });
      }
    }

    updateSkills(updatedSkills);
  }

  render() {
    const { data: skills, editMode, loggedInUser, isOwner } = this.props;
    const { showMore, rowLength } = this.state;

    const userType = loggedInUser ? loggedInUser.type : 'guest';
    const showMoreButton = skills.length > rowLength;
    const displayContents = skills.length > 0 || editMode;
    const moreOrLess = showMore ? 'See less' : 'See more';

    if (!displayContents) return null;

    if (editMode) {
      return (
        <SkillsSelector
          existing={skills}
          onSelect={(selected) => this.updateSkills(selected)}
          style={{ width: '100%', marginBottom: '20px' }}
        />
      );
    }

    return (
      <div>
        {isOwner && (
          <div className={styles.freelanceGig}>
            Add the Freelance Gig skill to opt in for non-specialized jobs.
          </div>
        )}
        <SkillsTiles
          skills={skills}
          showMore={showMore}
          rowLength={rowLength}
          userType={userType}
        />
        {showMoreButton && (
          <div
            className={styles.more}
            onClick={() => this.setState({ showMore: !showMore })}
          >
            {moreOrLess}
          </div>
        )}
      </div>
    );
  }
}
