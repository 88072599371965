import React, { memo } from 'react';
import Verified from 'components/Badges/Verified/Verified2';
import Pro from 'components/Badges/Pro/Pro';
import { calculateUserRating, flashHighlight } from 'helpers/HelperFunctions';
import Icon from 'components/Icon/Icon';
import Rating from 'components/Rating/Rating';
import LastSeen from 'components/Shared/LastSeen';
import Roles from './Roles';
import Displayname from './Displayname';
import styles from './DisplaynameSection.module.scss';
import PropTypes from 'prop-types';

const { container, nameContainer } = styles;
// import Icon from 'components/Icon/Icon';

const NameRow = ({ isOwner, user }) => (
  <div className={nameContainer}>
    <Displayname
      isHeader
      isOwner={isOwner}
      user={user}
      userData={user.displayname}
      updateKey="displayname"
      isEmpty={user.displayname === ''}
    />

    <Verified
      user={user}
      style={{
        top: 0,
        marginLeft: '5px',
        verticalAlign: 'middle',
        marginTop: '4px',
      }}
    />

    <Pro user={user} style={{ marginLeft: '3px' }} />

    <div className={styles.lastSeen}>
      <LastSeen large user={user} />
    </div>
  </div>
);

NameRow.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const DisplaynameSection = ({ isOwner, onRatings, user }) =>
  user && (
    <div className={container}>
      <NameRow isOwner={isOwner} user={user} />

      <Roles
        isHeader
        isOwner={isOwner}
        updateKey="featured_roles"
        isEmpty={user && user.featured_roles.length === 0}
        userData={user.featured_roles}
        user={user}
      />

      <span className={styles.rating}>
        {user.rating.count > 0 && user.rating.avg ? (
          <div>
            <Icon
              icon="&#xe602;"
              style={{ marginRight: '8px' }}
              color="#ffd700"
              size="18px"
              onClick={() => flashHighlight('portfolio-testimonials')}
            />
            {user.rating.avg.toFixed(1)} &#40;
            <span className="pointer text-underline" onClick={onRatings}>
              {`${user.rating.count}`}
              {user.rating.count > 1 ? (
                <span> reviews</span>
              ) : (
                <span> review</span>
              )}
            </span>
            &#41;
          </div>
        ) : (
          <Rating
            rating={calculateUserRating(user)}
            className="fs-18 left"
            onClick={() => flashHighlight('portfolio-testimonials')}
          />
        )}
      </span>
    </div>
  );

DisplaynameSection.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  onRatings: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default memo(DisplaynameSection);
