import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Testimonial from 'components/Testimonials/Testimonial/Testimonial';
import { withEditFunctionality } from 'components/Portfolio/PortfolioAbout/components/hoc/withEditFunctionality';

@withEditFunctionality
export default class Testimonials extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    loggedInUser: PropTypes.object.isRequired,
    optionalFunction: PropTypes.func,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
  }

  renderTestimonials(testimonialsArray) {
    return (
      <div>
        {testimonialsArray.map((testimonial, key) => {
          if (key < 3) {
            return (
              <Testimonial
                testimonial={testimonial}
                testimonialKey={testimonial.id}
                user={this.props.user}
                key={`testimonial${testimonial.id}`}
              />
            );
          }
        })}
      </div>
    );
  }

  render() {
    const testimonialsArray = this.props.data;
    const { loggedInUser, user, optionalFunction: onTestimonials } = this.props;
    return (
      <div>
        {this.renderTestimonials(testimonialsArray)}
        {testimonialsArray.length > 0 &&
        ((loggedInUser && loggedInUser.id !== user.id) || !loggedInUser) ? (
          <div onClick={onTestimonials} id="portfolio-testimonials">
            <a
              className="lato-bold"
              style={{ cursor: 'pointer', marginBottom: '20px' }}
            >
              See all testimonials{' '}
            </a>
          </div>
        ) : null}
        {loggedInUser && loggedInUser.id === user.id ? (
          <div onClick={onTestimonials} id="portfolio-testimonials">
            <a
              className="lato-bold"
              style={{ cursor: 'pointer', marginBottom: '20px' }}
            >
              Manage{' '}
              {user.stats.num_of_testimonials === 0
                ? 'your'
                : user.stats.num_of_testimonials}{' '}
              testimonial
              {user.stats.num_of_testimonials !== 1 ? 's' : ''}
              {user.stats.num_of_testimonials_pending &&
              user.stats.num_of_testimonials_pending > 0
                ? ' (' + user.stats.num_of_testimonials_pending + ' Pending)'
                : ''}{' '}
            </a>
          </div>
        ) : null}
      </div>
    );
  }
}
