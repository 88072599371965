import PropTypes from 'prop-types';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';

import QualificationForm from './QualificationForm/QualificationForm';
import QualificationDetails from './QualificationDetails/QualificationDetails';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'react-bootstrap';
import SvgIcon from 'components/SvgIcon/SvgIcon';

import { useDispatch } from 'react-redux';
import { update as updateUser } from 'redux/modules/auth';
import useAuth from 'hooks/useAuth';

import styles from './Qualification.module.scss';
import hocStyles from '../../hoc/hoc.module.scss';
import Loader from 'components/Loader/Loader';

const { headerContainer, header, editBtn, editWrp, loader } = hocStyles;

const Qualification = ({ userData, isOwn, user, isEmpty, placeholder }) => {
  const [qualification, setQualification] = useState(userData);
  const [displayedQualification, setDisplayedQualification] = useState(
    userData.filter((item) => !item.organization)
  );
  const dispatch = useDispatch();
  const { lastUpdated, updating, updated } = useAuth();
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setQualification(userData);
    setDisplayedQualification(userData.filter((item) => !item.organization));
  }, [userData]);

  const addNewQualification = useCallback(
    (newQualification) => {
      const updatedQualification = [...qualification, newQualification];
      setQualification(updatedQualification);

      const updatedDisplayedQualification = [
        ...displayedQualification,
        newQualification,
      ];
      setDisplayedQualification(updatedDisplayedQualification);
    },
    [qualification, displayedQualification]
  );

  const deleteQualification = useCallback(
    (index) => {
      const educationToDelete = displayedQualification[index];
      const updatedDisplayedEducation = displayedQualification.filter(
        (_, idx) => index !== idx
      );
      setDisplayedQualification(updatedDisplayedEducation);

      const originalIndex = qualification.findIndex(
        (ed) => ed === educationToDelete
      );
      const updatedQualification = qualification.filter(
        (_, idx) => originalIndex !== idx
      );
      setQualification(updatedQualification);
      handleEdit('qualifications', updatedQualification);
    },
    [qualification, displayedQualification]
  );

  const updateQualification = useCallback(
    (updatedQualification, index) => {
      const editedQualification = _.cloneDeep(qualification);
      const displayedItem = displayedQualification[index];
      const originalIndex = qualification.findIndex(
        (ed) => ed === displayedItem
      );
      editedQualification[originalIndex] = updatedQualification;
      setQualification(editedQualification);

      const updatedDisplayedQualification = _.cloneDeep(displayedQualification);
      updatedDisplayedQualification[index] = updatedQualification;
      setDisplayedQualification(updatedDisplayedQualification);
    },
    [qualification]
  );

  const handleEdit = useCallback(
    (editMode, qualificationUpdates = null) => {
      const updates = qualificationUpdates
        ? { education: qualificationUpdates }
        : { education: qualification };

      if (Object.keys(qualification).length > 0) {
        dispatch(
          updateUser(
            user,
            { ...updates, portfolioUpdate: true },
            false,
            [
              'skills:accepted(null)',
              'sectors',
              'tools',
              'education',
              'language',
              'diversity',
            ],
            '',
            editMode
          )
        ).then(() => {
          setQualification(qualification);
          setDisplayedQualification(
            qualificationUpdates
              ? qualificationUpdates.filter(
                  (qualificationItem) =>
                    qualificationItem.qualification &&
                    !qualificationItem.organization
                )
              : displayedQualification.filter(
                  (qualificationItem) =>
                    qualificationItem.qualification &&
                    !qualificationItem.organization
                )
          );
          setEditing(false);
        });
      } else {
        setEditing(false);
      }
    },
    [dispatch, qualification, user]
  );

  const renderQualificationDetails = useCallback(() => {
    return displayedQualification.map((qualification, index) => {
      const lastEntry = displayedQualification.length - index === 1;
      const key = qualification.id
        ? `quDetails${qualification.id}`
        : `quDetails${qualification.ended_at}`;
      if (!qualification.qualification) return <noscript key={key} />;
      return (
        <QualificationDetails
          key={key}
          lastEntry={lastEntry}
          index={index}
          qualification={qualification}
          isOwn={isOwn}
          user={user}
          deleteQualification={() => deleteQualification(index)}
        />
      );
    });
  }, [displayedQualification]);

  const renderQualificationForms = () => {
    const noQualification = displayedQualification.length === 0;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {displayedQualification.map((qualificationItem, index) => {
          const isLast = index === displayedQualification.length - 1;
          return (
            <QualificationForm
              key={index}
              index={index}
              updateQualification={updateQualification}
              currentQualification={qualificationItem}
              isLast={isLast}
              deleteQualification={() => deleteQualification(index)}
              addNewQualification={addNewQualification}
            />
          );
        })}
        {noQualification && (
          <Button
            bsStyle={'primary'}
            style={{
              alignSelf: 'center',
              width: '40%',
              maxWidth: '100%',
              marginTop: '10',
            }}
            onClick={() => addNewQualification({})}
          >
            <strong>+ Add Qualification</strong>
          </Button>
        )}
      </div>
    );
  };

  const instanceIsUpdating = useMemo(
    () => lastUpdated === 'qualifications',
    [lastUpdated]
  );

  const successTick = !updating &&
    instanceIsUpdating &&
    updated &&
    !editing && <SvgIcon size="25" icon="tick_circle" color="#0fe2af" />;

  return (
    <div className={styles.container}>
      <div className={headerContainer}>
        <h3 className={header} style={{ marginTop: '5px' }}>
          Qualifications
        </h3>
        {user && isOwn && (
          <div className={editWrp} style={{ left: '2em', paddingRight: 20 }}>
            {successTick}
            {instanceIsUpdating && updating ? (
              <Loader loading className={loader} />
            ) : (
              <button
                id="edit-bio"
                className={editBtn}
                onClick={() => setEditing(true)}
                type="button"
              >
                <SvgIcon size="21" icon="pencil" color="#666666" />
              </button>
            )}
          </div>
        )}
      </div>
      {isEmpty && !editing && placeholder}
      {renderQualificationDetails()}
      <Modal show={editing} onHide={() => handleEdit('qualifications')}>
        <ModalHeader closeButton>
          <h4 className="lato-bold">Editing Qualification</h4>
        </ModalHeader>
        <ModalBody bsClass={styles.editModal}>
          {renderQualificationForms()}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={updating}
            onClick={() => {
              setQualification(userData);
              setEditing(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={updating}
            onClick={() => handleEdit('qualifications')}
            bsStyle="primary"
            className="ml--"
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

Qualification.propTypes = {
  userData: PropTypes.array,
  user: PropTypes.object,
  isOwn: PropTypes.bool,
  isEmpty: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default Qualification;
