import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { format, addYears, isValid } from 'date-fns';

import Textarea from 'react-textarea-autosize';
import {
  Button,
  ListGroup,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'react-bootstrap';

import { TODAY } from 'constants/constants';

import Markdown from 'components/Shared/Markdown';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import Label from 'components/Label/Label';
import Icon from 'components/Icon/Icon';
import DayRateForm from 'components/Shared/DayRateForm/DayRateForm';
import LocationInput from 'components/LocationInput/LocationInput';
import DatePicker from 'components/Shared/DatePicker';
import Loader from 'components/Loader/Loader';

import { useDispatch } from 'react-redux';
import { update as updateUser } from 'redux/modules/auth';
import useAuth from 'hooks/useAuth';

import styles from './Bio.module.scss';
import hocStyles from '../../hoc/hoc.module.scss';

const { headerContainer, editBtn, editWrp, loader } = hocStyles;

const bioTemplates = {
  Designer:
    "I'm a designer from...\n\nGive an introduction about yourself, who are you, what are your most important skills and what differentiates you from other designers. This is the first thing clients will see. They are often interested in sector experience and knowledge, so please mention it.\n\n---\n\n**Employment and project experience**\n\n**Senior Designer** Apple Inc.\n_January 2017 - March 2017_\n\n**UX Designer** Airbnb Inc.\n_January 2019 - March 2019_",
  Developer:
    "I'm a developer who specializes in... from...\n\nGive an introduction about yourself, who are you, what are your most important skills and what differentiates you from other devs. This is the first thing clients will see. They are often interested in sector experience and knowledge, so please mention it.\n\n---\n\n**Employment and project experience**\n\n**Senior Developer** Salesforce Inc.\n_January 2017 - March 2017_\n\n**Full-stack Developer** Microsoft Inc.\n_January 2019 - March 2019_",
  Musician:
    "I'm a musician who specializes in... from...\n\nGive an introduction about yourself, who are you, what are your most important skills and what differentiates you from other musicians. This is the first thing clients will see. They are often interested in sector experience and knowledge, so please mention it.\n\n---\n\n**Employment and project experience**\n\n**Sound Engineer** Acme Co.\n_January 2017 - March 2017_\n\n**Music Producer** Universal Group Inc.\n_January 2019 - March 2019_",
  Filmmaker:
    "I'm a filmmaker and videographer from...\n\nGive an introduction about yourself, who are you, what are your most important skills and what differentiates you from other filmmakers. This is the first thing clients will see. They are often interested in sector experience and knowledge, so please mention it.\n\n---\n\n**Employment and project experience**\n\n**Videographer** Acme Co.\n_January 2017 - March 2017_\n\n**Director of Photography** HBO\n_January 2019 - March 2019_",
  General:
    "I'm a freelancer who specializes in... from...\n\nGive an introduction about yourself, who are you, what are your most important skills and what differentiates you from other freelancers. This is the first thing clients will see. They are often interested in sector experience and knowledge, so please mention it.\n\n---\n\n**Employment and project experience**\n\n**Senior Designer** Acme Co.\n_January 2017 - March 2017_\n\n**UX Designer** Microsoft Inc.\n_January 2019 - March 2019_",
};

const Bio = ({
  showMore,
  showShowMoreButton,
  user,
  isOwn,
  onShowMore,
  onReport,
  sentReport,
  onEmailVerify,
  locationFindUrl,
}) => {
  const dispatch = useDispatch();
  const { lastUpdated, updating, updated } = useAuth();

  const [date, setDate] = useState(new Date(user.available_at || TODAY));
  const [editing, setEditing] = useState(false);
  const [editBio, setEditBio] = useState(user.bio || '');
  const [editDayRate, setEditDayRate] = useState(user.day_rate || 0);
  const [editDayRateCurrency, setEditDayRateCurrency] = useState(
    user.day_rate_currency || 'usd'
  );
  const [editAvailability, setEditAvailability] = useState(
    user.availability_at || ''
  );
  const [editLocation, setEditLocation] = useState({
    selected: false,
    text: user.location.text || '',
  });

  const handleEdit = (editMode) => {
    const updates = {};

    if (editBio !== user.bio) {
      updates.bio = editBio;
    }

    if (editLocation.text !== user.location.text) {
      updates.location = {
        lat: editLocation.lat,
        lon: editLocation.lon,
        city: editLocation.city,
        country: editLocation.country,
        country_code: editLocation.country_code,
        method: 'html5',
      };
    }

    if (editDayRate !== user?.day_rate) {
      if (editDayRate / 100 > 3000) {
        updates.day_rate = 3000 * 100;
      } else if (editDayRate == 0 || editDayRate == '') {
        updates.day_rate = '';
      } else {
        updates.day_rate = editDayRate;
      }
    }

    if (editDayRateCurrency !== user.day_rate_currency) {
      updates.day_rate_currency = editDayRateCurrency;
    }

    if (editAvailability !== user.available_at) {
      updates.available_at = editAvailability;
    }

    if (Object.keys(updates).length > 0) {
      dispatch(updateUser(user, updates, false, [], '', editMode)).then(() =>
        setEditing(false)
      );
    } else {
      setEditing(false);
    }
  };

  const renderUserLocation = (
    <div>
      <SvgIcon
        icon="location_pin"
        size="15"
        color="#666"
        style={{ marginRight: '15px' }}
      />
      {user ? (
        user.location.text === '' ? (
          'Unknown'
        ) : user.location.text.length > 35 ? (
          `${user.location.text.substring(0, 32)}...`
        ) : (
          user.location.text
        )
      ) : (
        <span
          style={{
            minWidth: '140px',
            height: '10px',
            background: '#eee',
            display: 'inline-block',
          }}
        />
      )}
    </div>
  );

  const instanceIsUpdating = useMemo(
    () => lastUpdated === 'contentEditMode',
    [lastUpdated]
  );

  const successTick = !updating &&
    instanceIsUpdating &&
    updated &&
    !editing && <SvgIcon size="25" icon="tick_circle" color="#0fe2af" />;

  return (
    <div className={styles.bioPanel}>
      <div
        id="expandable_about"
        className={
          !showMore && !editing && showShowMoreButton ? styles.pitchFade : ''
        }
        style={{
          maxHeight:
            showMore || editing || !showShowMoreButton ? 'none' : '400px',
          overflow:
            showMore || editing || !showShowMoreButton ? 'visible' : 'hidden',
        }}
      >
        <Row style={{ margin: '5px' }}>
          <ListGroup style={{ marginBottom: 0 }}>
            <div className={headerContainer}>
              {user.available_at &&
                moment.utc(user.available_at).isAfter(moment()) && (
                  <div className="mb">
                    <div className="mb lato-bold">Next availability:</div>
                    <div>{moment(user.available_at).format('LL')}</div>
                  </div>
                )}
              {(!user.available_at ||
                moment.utc(user.available_at).isBefore(moment())) && (
                <div className="mb">
                  <Label color="success">Available to hire</Label>
                </div>
              )}
              {user && isOwn && (
                <div
                  className={editWrp}
                  style={{ left: '2em', paddingRight: 20 }}
                >
                  {successTick}
                  {instanceIsUpdating && updating ? (
                    <Loader loading className={loader} />
                  ) : (
                    <button
                      id="edit-bio"
                      className={editBtn}
                      onClick={() =>
                        user.email_verified ? setEditing(true) : onEmailVerify()
                      }
                      type="button"
                    >
                      <SvgIcon size="21" icon="pencil" color="#666666" />
                    </button>
                  )}
                </div>
              )}
            </div>
            <Row style={{ marginLeft: '0px' }}>
              <div>
                {isOwn ? (
                  renderUserLocation
                ) : (
                  <a
                    href={locationFindUrl || '/post'}
                    style={{ color: '#333333' }}
                  >
                    {renderUserLocation}
                  </a>
                )}
              </div>
            </Row>
            <div>
              <div
                style={{
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-line',
                }}
              >
                <div className={styles.bio}>
                  {user.bio.length <= 0 ? (
                    'No bio'
                  ) : (
                    <Markdown text={user.bio} />
                  )}
                </div>

                {isOwn && !user.bio && !editing && (
                  <div className="mt">
                    Add your availability, location, day rate and bio here.
                  </div>
                )}
              </div>
            </div>
          </ListGroup>
        </Row>
        {!isOwn && (
          <Row style={{ margin: '5px' }}>
            <Button
              bsSize="xs"
              className="pl pr right"
              onClick={onReport}
              style={{ backgroundColor: '#eee', color: '#777' }}
              disabled={sentReport}
            >
              <Icon icon="&#xe618;" /> Report
            </Button>
          </Row>
        )}
      </div>
      {!editing && showShowMoreButton && (
        <a
          onClick={onShowMore}
          style={{
            margin: '10px auto 0',
            display: 'block',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          See {showMore ? 'less' : 'more'}
        </a>
      )}
      <Modal
        show={editing}
        onHide={() => {
          handleEdit('contentEditMode');
        }}
      >
        <ModalHeader closeButton />
        <ModalBody bsClass={styles.editModal}>
          <div className="mb">
            <div className="lato-bold">Next availability</div>
            <div>{`If currently available, put today's date`}</div>
            <div className={styles.datePickerContainer}>
              <DatePicker
                className={`form-control mt+ ${styles.datePicker}`}
                isClearable={false}
                dateFormat="do MMMM"
                minDate={TODAY}
                maxDate={addYears(TODAY, 1)}
                onChange={(selectedDate) => {
                  if (isValid(selectedDate)) {
                    setDate(selectedDate);
                    setEditAvailability(
                      format(selectedDate, 'yyyy-MM-dd HH:mm:ss')
                    );
                  }
                }}
                placeholderText="Select availability"
                selected={date}
              />
            </div>
          </div>
          <div className="mb mt">
            <div className="mb- lato-bold">{`What's your nearest city?`}</div>
            <LocationInput
              style={{
                display: 'inline-block',
                minWidth: '250px',
              }}
              defaultValue={editLocation.text}
              shouldClearOnBlur={!editLocation.selected}
              onChangePlace={(location) =>
                setEditLocation({ selected: true, ...location })
              }
              id="changeLocation"
            />
          </div>
          <div className="mt++">
            <div className="mb- lato-bold">Bio:</div>
            <Textarea
              className="form-control"
              style={{ width: '100%', resize: 'vertical' }}
              placeholder="Tell clients who you are, what you've done, your previous experience, and what makes you stand out."
              onChange={(event) => setEditBio(event.target.value)}
              value={editBio}
              rows={8}
              minRows={5}
              maxRows={8}
              maxLength="3000"
              id="bioArea"
            />
            {editBio.length > 2500 && (
              <div>
                <em>Characters left: {3000 - editBio.length}</em>
              </div>
            )}
            <div style={{ margin: '5px 0px' }}>
              {`It's important to have a detailed bio.`}{' '}
              <a
                href="https://www.twine.net/blog/designing-your-portfolio/"
                target="_blank"
                title="Designing your portfolio"
                rel="noreferrer"
              >
                Read our tips
              </a>
              .
            </div>
            <div>
              <div style={{ marginBottom: '5px' }}>Example templates:</div>
              <div>
                {Object.keys(bioTemplates).map((template) => (
                  <Button
                    key={template}
                    bsSize="small"
                    onClick={() => setEditBio(bioTemplates[template])}
                    style={{
                      marginRight: '5px',
                      marginBottom: '5px',
                    }}
                  >
                    {template}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <div className="mb mt++">
            <div className="lato-bold">{`What's your standard day rate?`}</div>
            <div className="mb">
              {`This is kept private. It is used to help match you to clients`}
            </div>
            <DayRateForm
              handleDayRateChange={(rate) => setEditDayRate(rate)}
              handleDayRateCurrencyChange={(currency) =>
                setEditDayRateCurrency(currency)
              }
              dayRate={editDayRate}
              dayRateCurrency={editDayRateCurrency}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button disabled={updating} onClick={() => setEditing(false)}>
            Cancel
          </Button>
          <Button
            disabled={updating}
            onClick={() => handleEdit('contentEditMode')}
            bsStyle="primary"
            className="ml--"
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

Bio.propTypes = {
  showMore: PropTypes.bool,
  showShowMoreButton: PropTypes.bool,
  user: PropTypes.object,
  isOwn: PropTypes.bool,
  onShowMore: PropTypes.func,
  onReport: PropTypes.func,
  sentReport: PropTypes.bool,
  onEmailVerify: PropTypes.func,
  locationFindUrl: PropTypes.string,
};

export default Bio;
